<template>
    <div id="orderConfirm" class="col-md-12 px-3 mt-2 mb-5 mx-auto">
      <div class="row">
        <div class="col-md-12">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/" class="text-decoration-none"
                  ><font-awesome icon="home"
                /></router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link
                  to="/account"
                  class="text-decoration-none text-capitalize"
                  >Account</router-link
                >
              </li>
              <li class="breadcrumb-item">
                <a href="#" class="text-decoration-none text-capitalize"
                  >Saved PC</a
                >
              </li>
            </ol>
          </nav>
        </div>
        <div class="col-md-12" v-if="pcLists.length">
          <table
            class="table table-hover table-border table-striped table-responsive"
          >
            <thead class="cbg-primary text-white">
              <th class="align-middle">SL#</th>
              <th class="align-middle">PC Name</th>
              <th class="align-middle">Description</th>
              <th class="align-middle">Date</th>
              <th class="align-middle text-center">Action</th>
            </thead>
            <tbody>
              <tr v-for="(pc, i) in pcLists" :key="pc.id">
                <td>{{ ++i }}</td>
                <td>{{ pc.name }}</td>
                <td>{{ pc.description }}</td>
                <td>{{ dateTime(pc.created_at) }}</td>
                <td>
                    <router-link to="" title="PC Details"><font-awesome icon="eye"></font-awesome></router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-12" v-else>
          <h4 class="text-center py-5">You don't have any saved PCs!</h4>
        </div>
      </div>

      <!-- loading view -->
      <LoadingView v-if="showLoading"/>

    </div>
  </template>
  <script>
  import moment from "moment";
  import LoadingView from '@/components/Layouts/LoadingView.vue';

  export default {
    name: "SavePcList",
    props: ["userInfo"],
    components: {
      LoadingView
    },
    data() {
      return {
        showLoading: true,
        pcLists: [],
        customerId: this.userInfo.user.customer_id,        
      };
    },
    mounted() {
      this.getSavedPC();
    //   console.log(this.customerId)
    },
    methods: {
      getSavedPC() {
        // on page loading
        this.showLoading = true;

        this.$store
          .dispatch("savedPC", this.customerId)
          .then((response) => {
            this.pcLists = response.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            // off page loading
            this.showLoading = false;
          });
      },

    //   date format function
      dateTime(value) {
        return moment(value).format("DD MMM, YYYY hh:mma");
      },
    },
    watch: {},
  };
  </script>
  <style scoped>
  .cbg-primary,
  .cbtn-primary {
    background: #293a99;
    color: white;
  }
  .link-btn {
    background: #1934cfad;
    color: white;
  }
  
  .link-active {
    background: #00008bc7;
    box-shadow: 0px 0px 4px 1px #1934cf;
  }
  .btn-primary:hover {
    box-shadow: 0px 3px 3px 1px darkblue;
    color: #fffcfccc;
  }
  .btn-secondary:hover {
    box-shadow: 0px 3px 3px 1px #648fb9;
    color: #fffcfccc;
  }
  </style>