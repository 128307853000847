import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProductDetailView from '../components/ProductDetailView.vue'
import OfferProductDetailView from '../components/OfferProductDetailView.vue'
import CategoryProductView from '../components/CategoryProductView.vue'
// import CategoryProduct from '../components/CategoryProduct.vue'
// import SubCategoryProduct from '../components/SubCategoryProduct.vue'
import LoginView from '../components/LoginView.vue'
import RegisterView from '../components/RegisterView.vue'
import AccountView from '../components/AccountView.vue'
import ProfileView from '../components/ProfileView.vue'
import PasswordChange from '../components/PasswordChange.vue'
import ProductQuestions from '../components/ProductQuestions.vue'
import ProductReviews from '../components/ProductReviews.vue'
import CheckOut from '../components/CheckOut.vue'
import OrderConfirm from '../components/OrderConfirm.vue'
import OrderView from '../components/OrderView.vue'
import OfferView from '../components/OfferView.vue'
import OfferInfo from '../components/OfferInfo.vue'
import OfferProducts from '../components/OfferProducts.vue'
import CompareProduct from '../components/CompareProduct.vue'
import OrderDetails from '../components/OrderDetails.vue'
import BrandProducts from '../components/BrandProducts.vue'
import SearchProducts from '../components/SearchProducts.vue'
import TermsConditions from '../views/TermsConditions.vue'
import RefundReturnPolicy from '../views/RefundReturnPolicy.vue'
import OnlineDelivery from '../views/OnlineDelivery.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import AboutUs from '../views/AboutUs.vue'
import ContactView from '../views/ContactView.vue'
import PcBuilder from '../components/PcBuilder.vue'
import PcBuilderComponets from '../components/PcBuilderComponets.vue'
import BudgetPc from '../components/BudgetPc.vue'
import SavePcFrom from '../components/SavePcFrom.vue'
import SavePcList from '../components/SavePcList.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/product-details/:productSlug/:typeSlug?/:categorySlug?/:subcategorySlug?',
    name: 'productDetails',
    component: ProductDetailView
  },
  {
    path: '/products-details/:productSlug/:offerSlug/:offerId',
    name: 'offerProductDetails',
    component: OfferProductDetailView
  },
  {
    path: '/product/:typeSlug',
    name: 'typeProducts',
    component: CategoryProductView
  },
  {
    path: '/products/:typeSlug/:categorySlug',
    name: 'categoryProducts',
    component: CategoryProductView
  },
  {
    path: '/productses/:typeSlug/:categorySlug/:subcategorySlug',
    name: 'subCategoryProducts',
    component: CategoryProductView
  },
  {
    path: '/login/:redirectUrl?',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/account',
    name: 'account',
    component: AccountView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/account/profile',
    name: 'accountProfile',
    component: ProfileView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/account/password-change',
    name: 'accountPasswordChange',
    component: PasswordChange,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: OrderView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/save-pc',
    name: 'savePC',
    component: SavePcFrom,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/saved-pc-list',
    name: 'savePCList',
    component: SavePcList,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/product-questions/:productSlug/:productId/:typeSlug?/:categorySlug?/:subcategorySlug?/:offerSlug?',
    name: 'ProductQuestions',
    component: ProductQuestions,
  },
  {
    path: '/product-reviews/:productSlug/:productId/:typeSlug?/:categorySlug?/:subcategorySlug?/:offerSlug?',
    name: 'ProductReviews',
    component: ProductReviews,
  },
  {
    path: '/checkout',
    name: 'CheckOut',
    component: CheckOut,
  },
  {
    path: '/order-confirm/:order_id',
    name: 'OrderConfirm',
    component: OrderConfirm,
  },
  {
    path: '/offers',
    name: OfferView,
    component: OfferView,
  },
  {
    path: '/offer-info/:slug/:offer_id',
    name: OfferInfo,
    component: OfferInfo,
  },
  {
    path: '/offer/offer-products/:slug/:offer_id',
    name: OfferProducts,
    component: OfferProducts,
  },
  {
    path: '/compare-products',
    name: CompareProduct,
    component: CompareProduct,
  },
  {
    path: '/order-details/:order_id',
    name: OrderDetails,
    component: OrderDetails,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/brand/:brandSlug/:brandId',
    name: BrandProducts,
    component: BrandProducts,
  },
  {
    path: '/product-search/:searchText',
    name: SearchProducts,
    component: SearchProducts,
  },
  {
    path: '/terms-conditions',
    name: TermsConditions,
    component: TermsConditions,
  },
  {
    path: '/refund-return-policy',
    name: RefundReturnPolicy,
    component: RefundReturnPolicy,
  },
  {
    path: '/online-delivery',
    name: OnlineDelivery,
    component: OnlineDelivery,
  },
  {
    path: '/privacy-policy',
    name: PrivacyPolicy,
    component: PrivacyPolicy,
  },
  {
    path: '/about-us',
    name: AboutUs,
    component: AboutUs,
  },
  {
    path: '/contact',
    name: ContactView,
    component: ContactView,
  },
  {
    path: '/pc-builder',
    name: PcBuilder,
    component: PcBuilder,
  },
  {
    path: '/pc-builder-components/:maxQty/:componentId/:slug/:id?',
    name: 'PcBuilderComponets',
    component: PcBuilderComponets,
  },
  {
    path: '/budget-pc/:maxAmount?',
    name: 'BudgetPc',
    component: BudgetPc,
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
