<template>
    <div id="loading-area">
      <font-awesome icon="spinner" spin id="loadingIcon" />
    </div>
</template>

<style>
#loading-area{
  position: fixed;
  width: 100%;
  height: 100%;
  background: #0000007d;
  z-index: 9999;
  text-align: center;
  left: 0;
  top: 0;
}
#loadingIcon{
  position: absolute;
  margin: 0 auto;
  font-size: 80px;
  top: 43%;
  color: #d703cf;
  left: 47%;
}
</style>