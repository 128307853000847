<template>
  <div class="product-area col-md-12 px-3 mt-2 mb-5">
    <div class="row">
      <div class="col-md-12">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/" class="text-decoration-none"
                ><font-awesome icon="home"
              /></router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link
                to="/offers"
                class="text-decoration-none text-capitalize"
                >Offers</router-link
              >
            </li>
            <li class="breadcrumb-item">
              <router-link
                :to="'/offer-info/' + pagePar.slug + '/' + pagePar.offer_id"
                class="text-decoration-none text-capitalize"
                >{{ pagePar.slug.replace(/-/g, "") }}</router-link
              >
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-xl-12 col-md-12 col-sm-12 col-12">
        <div class="row bg-white sort-area rounded mx-1 py-2 mb-3">
          <div class="col-md-12">
            <div class="filter-btn float-start px-2 py-1" @click="filterClose">
              <font-awesome icon="bars" class="me-1 d-inline-block" />
              <span class="d-inline-block">Filter</span>
            </div>
            <div class="sort-area float-end">
              <div class="per-page d-inline-block me-2 px-2 py-1">
                <label for="">Show: &nbsp;</label>
                <select v-model="this.pagePar.limit">
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="45">45</option>
                  <option value="70">70</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div class="filter-sort d-inline-block px-2 py-1">
                <label for="">Sort By: &nbsp;</label>
                <select v-model="this.pagePar.order">
                  <option value="">Default</option>
                  <option value="asc">Price (Low to High)</option>
                  <option value="desc">Price (High to Low)</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="pro-card-area mt-1" v-if="products.data">
          <div
            class="pro-card"
            v-for="product in products.data"
            :key="product.product_id"
          >
            <div class="pro-card-inner">
              <div class="marks" v-if="product.discount > 0">
                <span class="mark"
                  >Save: {{ product.discount.toLocaleString() }}৳</span
                >
              </div>
              <div class="pro-image">
                <router-link
                  :to="{
                    name: 'offerProductDetails',
                    params: {
                      productSlug: product.slug,
                      offerSlug: this.$route.params.slug,
                      offerId: this.$route.params.offer_id,
                    },
                  }"
                >
                  <img
                    v-if="product.thumbnail"
                    :src="product.thumbnail"
                    :alt="product.product_name"
                    class="thumble"
                  />
                  <img
                    v-else
                    src="@/assets/product-default.jpeg"
                    :alt="product.product_name"
                    class="thumble"
                  />
                </router-link>
              </div>
              <div class="pro-details">
                <h4 class="pro-name">
                  <router-link
                    :to="{
                      name: 'offerProductDetails',
                      params: {
                        productSlug: product.slug,
                        offerSlug: this.$route.params.slug,
                        offerId: this.$route.params.offer_id,
                      },
                    }"
                    class="nav-link p-0 text-start"
                    >{{ product.product_name }}</router-link
                  >
                </h4>
              </div>
              <div class="justify-content-center buy-btn">
                <div class="pro-price py-2 text-center pb-3">
                  {{
                    (product.sale_price - product.discount).toLocaleString()
                  }}৳
                  <small class="ml-3" v-if="product.discount > 0"
                    ><del class="text-secondary"
                      >{{ product.sale_price.toLocaleString() }}৳</del
                    ></small
                  >
                </div>
                <button
                  class="btn btn-out mb-2 btn-sm float-start py-2 col-12"
                  v-if="product.stock_status == 2"
                >
                  Stock Out
                </button>
                <button
                  class="btn btn-out mb-2 btn-sm float-start py-2 col-12"
                  v-else-if="product.stock_status == 3"
                >
                  Up Coming
                </button>
                <div v-else>
                  <router-link
                    v-if="product.variation_type"
                    class="btn btn-primary mb-2 btn-sm float-start col-12 py-2 border-0"
                    :to="{
                      name: 'offerProductDetails',
                      params: {
                        productSlug: product.slug,
                        offerSlug: this.$route.params.slug,
                        offerId: this.$route.params.offer_id,
                      },
                    }"
                  >
                    <font-awesome icon="cart-arrow-down" /> Buy Now
                  </router-link>
                  <button
                    v-else
                    class="btn btn-primary mb-2 btn-sm float-start col-12 py-2 border-0"
                    @click="addToCart(product)"
                  >
                    <font-awesome icon="cart-arrow-down" /> Buy Now
                    {{ product.variation_type }}
                  </button>
                </div>
                <button
                  class="btn btn-secondary mb-1 btn-sm float-end col-12 py-2"
                  @click="addtoComparef(product)"
                >
                  <font-awesome icon="plus" class="" /> Add to Compare
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="col-md-12 py-5 my-5">
          <h4 class="text-center py-5">Product Not Found!!!</h4>
        </div>
        <div class="row mt-4 px-3" v-if="products.total">
          <div class="col-md-12 bg-white rounded p-2 shadow">
            <div v-if="products.last_page > 1" class="d-inline-block">
              <div class="d-inline-block" v-if="products.current_page == 1">
                <button class="btn btn-sm btn-success me-2 mt-1 page-btn">
                  PREV
                </button>
              </div>
              <div class="d-inline-block" v-else>
                <button
                  class="btn btn-sm btn-success me-2 mt-1 page-btn"
                  @click="paginationPro(products.current_page - 1)"
                >
                  PREV
                </button>
              </div>
              <div
                class="d-inline-block"
                v-for="page in products.last_page"
                :key="page"
              >
                <button
                  v-if="
                    page > products.current_page - 4 &&
                    page < products.current_page + 4
                  "
                  class="btn btn-sm link-btn me-2 mt-1"
                  :class="page == products.current_page ? 'link-active' : ''"
                  @click="paginationPro(page)"
                >
                  {{ page }}
                </button>
              </div>
              <div
                class="d-inline-block"
                v-if="products.current_page == products.last_page"
              >
                <button class="btn btn-sm btn-success mt-1 page-btn">
                  NEXT
                </button>
              </div>
              <div class="d-inline-block" v-else>
                <button
                  class="btn btn-sm btn-success mt-1 page-btn"
                  @click="paginationPro(products.current_page + 1)"
                >
                  NEXT
                </button>
              </div>
            </div>
            <div class="page-number d-inline-block float-end">
              <p class="mt-1 mb-0">
                <small class="fw-bold page-btn"
                  >Showing {{ products.from }} to {{ products.to }} of
                  {{ products.total }} ({{ products.last_page }}
                  {{ products.last_page > 1 ? "Pages" : "Page" }})</small
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- loading view -->
    <LoadingView v-if="showLoading"/>

  </div>
</template>
<script>
import LoadingView from '@/components/Layouts/LoadingView.vue';

export default {
  name: "TypeProductView",
  props: ["imageBaseUrl", "filterModal"],
  components: {
    LoadingView,
  },
  data() {
    return {
      showLoading: true,
      pagePar: {
        page: 1,
        order: "asc",
        limit: 20,
      },
      products: [],
      buyModalShow: "",
      product_detail: "",
      cartForm: {
        variation_type_id: "",
        variation_id: "",
        qty: 1,
        product: "",
      },
    };
  },
  methods: {
    addtoComparef(product) {
      this.$store.dispatch("addtoCompare", product);
    },
    // product add to cart function
    addToCart(product) {
      let cartForm = {
        qty: 1,
        product: "",
      };
      cartForm.product = product;
      this.$store.dispatch("addToCart", cartForm);
      this.alertMsg = product.product_name;
      this.addAlert = "show d-block";
    },
    paginationPro(page) {
      this.pagePar.page = page;
      this.$store
        .dispatch("offerProducts", this.pagePar)
        .then((response) => {
          this.products = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    paginationFilterPro(page) {
      this.pagePar.page = page;
      this.$store
        .dispatch("offerProducts", this.pagePar)
        .then((response) => {
          this.products = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getProducts() {
      // on page loading
      this.showLoading = true;

      this.pagePar.slug = this.$route.params.slug;
      this.pagePar.offer_id = this.$route.params.offer_id;
      this.pagePar.page = 1;
      this.$store
        .dispatch("offerProducts", this.pagePar)
        .then((response) => {
          this.products = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          // off page loading
          this.showLoading = false;
        });
    },

    statusToggle() {
      this.statusShow = !this.statusShow;
    },
  },
  created() {
    this.getProducts();
  },
  mounted() {},
  watch: {
    $route() {
      if (this.$route.name == "OfferProducts") this.getProducts();
    },
    ["pagePar.order"]() {
      this.getProducts();
    },
    ["pagePar.limit"]() {
      this.getProducts();
    },
  },
};
</script>
<style scoped>
.feature {
  border-bottom: 2px solid #942a8f !important;
}
.thumble {
  height: 221px;
  width: 221px;
  text-align: center;
}
.pro-card-area {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  padding: 0;
  justify-content: flex-start;
  background: none;
}

.pro-card {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 5px 10px;
  margin-bottom: 0;
  display: flex;
  position: relative;
}
.pro-card-inner {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
}
.pro-card-inner:hover {
  box-shadow: 0px 0px 8px 1px gray;
}
.marks {
  display: flex;
  position: absolute;
  top: 5px;
  left: 0;
  z-index: 10;
  flex-direction: column;
  align-items: flex-start;
}
.mark {
  background: #ed283a;
  width: auto;
  color: #fff;
  font-size: 12px;
  padding: 3px 10px;
  line-height: 14px;
  margin-bottom: 2px;
  border-radius: 0 20px 20px 0;
  flex: 0 0 auto;
}

.pro-image {
  text-align: center;
  border-bottom: 3px solid rgba(55, 73, 187, 0.03);
  flex: 0 0 220px;
  padding: 20px;
  margin: 0;
}
.pro-image img {
  max-width: 100%;
}
.pro-details {
  padding: 10px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  border-bottom: 3px solid rgba(55, 73, 187, 0.03);
}
.price-card {
  font-size: 14px;
}
.pro-name {
  font-weight: 400;
  font-size: 15px;
}
.pro-price {
  line-height: 22px;
  font-size: 17px;
  font-weight: 600;
  color: #ef4a23;
}
.text-dark {
  color: black;
}
.buy-btn {
  padding: 10px;
}
.btn-primary {
  background: #f2f4f8;
  color: #932a8f;
  font-weight: bold;
  transition: 1s;
}
.btn-out {
  background: #d4d5d7;
  color: #932aaa;
  font-weight: bold;
}
.btn-primary:hover {
  background-color: #932a8f;
  box-shadow: 0px 3px 3px 1px #f2f4f8;
  color: #fffcfccc;
  transition: 1s;
}
.btn-secondary{
  background-color: #f2f4f86b;
  transition: 1s;
  font-weight: 500;
  border: 0;
  color: rgb(0, 0, 0);
}

.btn-secondary:hover {
  box-shadow: 0px 3px 3px 1px #f2f4f8;
  color: #932a8f;
  background-color: #cacdd5;
}
.gallary-img {
  height: 50px;
  width: 50px;
  border: 1px solid lightgray;
  padding: 5px;
  margin: 1px;
}
.price-bg {
  background: #f1f1f3d1;
}
.link-btn {
  background: #1934cfad;
  color: white;
}
.filter-border {
  border-bottom: 1px solid #80808057;
}
.link-active {
  background: #00008bc7;
  box-shadow: 0px 0px 4px 1px #1934cf;
}
.filter-btn,
.per-page,
.filter-sort {
  background: #e7e9ed;
  color: black;
  font-size: 14px;
  cursor: pointer;
}
.availability {
  height: 16px;
  width: 16px;
  position: relative;
  top: 3px;
}
.availability-grp:hover {
  background: #02189178;
  color: white;
}
.resp-filter-area {
  position: absolute;
  width: 280px;
  background: #f2f4f8;
  top: 0;
  left: 10px;
  box-shadow: 0 0 10px 5px grey;
  z-index: 999;
  padding: 10px;
  overflow-y: scroll;
  height: 100%;
}
.filter-area {
  display: block;
}
.filter-btn {
  display: none;
}
.page-btn {
  font-size: 11px;
}
@media (max-width: 1279px) {
  .pro-card {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (max-width: 1200px) {
  .filter-area {
    display: none;
  }
  .filter-btn {
    display: block;
  }
}
@media (min-width: 992px) {
  .modal-dialog {
    width: 95%;
  }
}
@media (max-width: 767px) {
  .pro-card {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .modal-dialog {
    width: 95%;
  }
}

.min {
  width: 47%;
  float: left;
}

.max {
  width: 47%;
  float: right;
}
.track-container {
  width: 100%;
  position: relative;
  cursor: pointer;
  height: 0.5rem;
}

.track,
.track-highlight {
  display: block;
  position: absolute;
  width: 100%;
  height: 0.5rem;
}

.track {
  background-color: #cccfd4;
}

.track-highlight {
  background-color: #293a99b3;
  z-index: 2;
}

.track-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  display: block;
  position: absolute;
  z-index: 2;
  width: 1.2rem;
  height: 1.2rem;
  top: calc(-50% - 0.15rem);
  margin-left: -0.7rem;
  border: none;
  background-color: #293a99;
  border-radius: 50%;
  -ms-touch-action: pan-x;
  touch-action: pan-x;
  transition: box-shadow 0.3s ease-out, background-color 0.3s ease,
    -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out,
    background-color 0.3s ease;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out,
    background-color 0.3s ease, -webkit-transform 0.3s ease-out;
}
.min-resp,
.max-resp {
  width: 113px;
}
</style>
