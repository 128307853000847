<template>
  <div id="header" class="col-md-12">
    <div class="row">
      <div class="col-md-12 top-header text-white py-1">
        <div class="logo d-inline-block px-3">
          <router-link to="/"
            ><img :src="logo" alt="" class="img-fluid logoimg"
          /></router-link>
        </div>
        <div class="search d-inline-block">
          <div class="form-group search-form ms-3">
            <div class="position-relative">
              <input
                type="text"
                v-model="searchTxt"
                class="form-control"
                placeholder="Search product ..."
                @keyup.enter="SearchEnter"
              />
                <router-link :to="searchTxt? '/product-search/' + searchTxt:'#'" class="position-absolute top-0 end-0 text-dark p-1 mt-1 me-1 rounded" @click="searchClose"><font-awesome icon="search"/></router-link>
            </div>
            <div
              class="search-result mt-1 shadow rounded"
              :class="searchProducts.length > 0 ? '' : 'd-none'"
            >
              <ul class="nav flex-column" @click="searchClose">
                <li
                  class="nav-item align-middle"
                  v-for="product in searchProducts.slice(0, 15)"
                  :key="product.product_id"
                >
                  <router-link
                    class="nav-link"
                    :to="{
                      name: 'productDetails',
                      params: {
                        productSlug: product.slug,
                      },
                    }"
                  >
                    <div class="d-inline-block float-start">
                      <img
                        :src="product.thumbnail"
                        :alt="product.product_name"
                        class="src-image pe-2"
                      />
                    </div>
                    <div class="">
                      <p class="mb-0">
                        {{ product.product_name.slice(0, 55) }}
                        {{ product.product_name.length > 55 ? "..." : "" }}
                        <br />
                        <span class="pro-price fw-bold"
                          >{{ product.sale_price.toLocaleString() }} ৳</span
                        >
                        <small class="ps-3" v-if="product.discount > 0"
                          ><del class="text-secondary"
                            >{{
                              (
                                product.original_price - product.sale_price
                              ).toLocaleString()
                            }}
                            ৳</del
                          ></small
                        >
                      </p>
                    </div>
                  </router-link>
                </li>
                <li
                  v-if="searchProducts.length > 15"
                  class="nav-item text-center"
                >
                  <router-link
                    class="nav-link btn-primary text-white text-center"
                    :to="'/product-search/' + searchTxt"
                    >See All Result...</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="top-menu d-inline-block float-end">
          <nav class="nav">
            <router-link to="/offers" class="nav-link text-white px-2">
              <div class="float-start">
                <font-awesome icon="gift" class="hisize mt-2" />
              </div>
              <div class="float-end">
                <h6 class="ps-2">Offers</h6>
                <p class="offer mb-0 ps-2">Latest Offers</p>
              </div>
            </router-link>
            <div class="nav-link text-white px-2">
              <div class="float-start">
                <font-awesome icon="user" class="hisize mt-2" />
              </div>
              <span class="float-end ml-3">
                <h6 class="ps-2 mb-0">Account</h6>
                <p class="offer mb-0" v-if="userInfo">
                  <router-link
                    to="/account"
                    class="nav-link ps-1 pe-0 d-inline-block account-li cl"
                    >Profile</router-link
                  >
                  or
                  <a
                    href="#"
                    class="nav-link px-0 d-inline-block account-li cl"
                    @click="logout"
                    >Logout</a
                  >
                </p>
                <p class="offer mb-0" v-else>
                  <router-link
                    to="/register"
                    class="nav-link ps-2 pe-0 d-inline-block account-li cl"
                    >Register</router-link
                  >
                  or
                  <router-link
                    to="/login"
                    class="nav-link px-0 d-inline-block account-li cl"
                    >Login</router-link
                  >
                </p>
              </span>
            </div>
            <router-link
              to="/pc-builder"
              class="nav-link text-white mt-2 pc-builder"
              >PC Builder</router-link
            >
          </nav>
        </div>
      </div>
      <div class="col-md-12 menu-area py-2 sticky-top" :style="{ position: menuPosition }">
        <nav class="big-menu">
          <ul class="mb-0 px-0">
            <li v-for="(type, ti) in productCategories" :key="type.id">
              <router-link
                :to="{
                  name: 'typeProducts',
                  params: { typeSlug: type.slug },
                }"
                >{{ type.category_name }}</router-link
              >
              <ul v-if="type.categories.length">
                <li v-for="category in type.categories" :key="category.id">
                  <router-link
                    :to="{
                      name: 'categoryProducts',
                      params: {
                        typeSlug: type.slug,
                        categorySlug: category.slug,
                      },
                    }"
                  >
                    <span class="d-inline-block px-2">{{
                      category.category_name
                    }}</span>
                    <font-awesome
                      icon="angle-right"
                      class="float-end"
                      v-if="category.sub_categories.length"
                    />
                  </router-link>
                  <ul
                    :class="[ti > 6 ? 'left' : 'right']"
                    v-if="category.sub_categories.length"
                  >
                    <li
                      v-for="sub_category in category.sub_categories"
                      :key="sub_category.id"
                    >
                      <router-link
                        :to="{
                          name: 'subCategoryProducts',
                          params: {
                            typeSlug: type.slug,
                            categorySlug: category.slug,
                            subcategorySlug: sub_category.slug,
                          },
                        }"
                        >{{ sub_category.category_name }}</router-link
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>

      <!-- compare and car icon area -->
      <div class="cart-compare-area">
        <div class="cart-area">
          <a class="nav-link text-white mt-1 shadow" href="#" @click="compareOpen">
            <font-awesome icon="shuffle" />
            <span class="count" v-if="compareProducts.length">{{
              compareProducts.length
            }}</span>
            <span class="count" v-else>0</span>
          </a>
        </div>
        <div class="compare-area">
          <a class="nav-link text-white mt-1 shadow" href="#" @click="cartOpen">
            <font-awesome icon="cart-arrow-down" />
            <span class="count" v-if="cartProducts.length">{{
              cartProducts.length
            }}</span>
            <span class="count" v-else>0</span>
          </a>
        </div>
      </div>
    </div>

    <!-- responsive section -->
    <div class="responsivemenus py-1">
      <div class="flex-fill text-start" @click="menuf()">
        <font-awesome icon="bars" class="res-menu" v-if="menuOpen" />
        <font-awesome icon="close" class="res-menu close-icon" v-if="menuClose" />
      </div>
      <div class="flex-fill text-center">
        <router-link to="/">
          <img :src="logo" alt="" class="img-fluid" style="max-height: 40px;"/>
        </router-link>
      </div>
      <div class="flex-fill text-end text-white">
        <font-awesome
          icon="magnifying-glass"
          @click="searchInShowf()"
          class="res-menu px-1 px-sm-2"
        />

        <div class="d-inline mx-0">
          <div class="dropdown d-inline-block">
            <button
              class="btn dropdown-toggle text-white restoggbtn"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <font-awesome icon="user" class="hisize mt-1" />
            </button>
            <ul
              v-if="userInfo"
              class="dropdown-menu res-dropmenu"
              aria-labelledby="dropdownMenuButton1"
            >
              <li>
                <router-link to="/account" class="dropdown-item"
                  >Profile</router-link
                >
              </li>
              <li>
                <a href="#" @click="logout" class="dropdown-item">Logout</a>
              </li>
            </ul>
            <ul
              v-else
              class="dropdown-menu"
              aria-labelledby="dropdownMenuButton1"
            >
              <li>
                <router-link to="register" class="dropdown-item"
                  >Register</router-link
                >
              </li>
              <li>
                <router-link to="login" class="dropdown-item"
                  >Login</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="menuShow"
      class="row responsive-menu-list bg-white"
      :style="{ height: windowHeight + 'px' }"
    >
      <div class="col-md-12 px-1 py-1">
        <nav class="sm-menu">
          <ul class="mb-0 px-0">
            <li v-for="typeres in productCategories" :key="typeres.type_id">
              <router-link
                :to="{
                  name: 'typeProducts',
                  params: { typeSlug: typeres.slug },
                }"
              >
                <span class="d-inline-block px-2">{{
                  typeres.category_name
                }}</span>
                <font-awesome
                  icon="angle-right"
                  class="float-end"
                  v-if="typeres.categories.length"
                />
              </router-link>
              <ul v-if="typeres.categories.length">
                <li v-for="category in typeres.categories" :key="category.id">
                  <router-link
                    :to="{
                      name: 'categoryProducts',
                      params: {
                        typeSlug: typeres.slug,
                        categorySlug: category.slug,
                      },
                    }"
                    class="pl-3"
                  >
                    <span class="d-inline-block px-2">{{
                      category.category_name
                    }}</span>
                    <font-awesome
                      icon="angle-right"
                      class="float-end"
                      v-if="category.sub_categories.length"
                    />
                  </router-link>
                  <ul v-if="category.sub_categories.length">
                    <li
                      v-for="sub_category in category.sub_categories"
                      :key="sub_category.sub_category_id"
                    >
                      <router-link
                        :to="{
                          name: 'subCategoryProducts',
                          params: {
                            typeSlug: typeres.slug,
                            categorySlug: category.slug,
                            subcategorySlug: sub_category.slug,
                          },
                        }"
                        >{{ sub_category.category_name }}</router-link
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div v-if="search" class="row responsive-search bg-white">
      <div class="col-md-12 px-1 py-1">
        <div class="input-group">
          <input
            type="text"
            v-model="searchTxt"
            class="form-control"
            placeholder="Search..."
            @keyup.enter="SearchEnter"
          />
          <span class="input-group-text" @click="SearchEnter"
            ><font-awesome icon="search"
          /></span>
          <div
            v-if="searchProducts.length"
            class="search-result-responsive mt-1 shadow rounded"
          >
            <ul class="nav flex-column" @click="searchClose">
              <li
                class="nav-item align-middle"
                v-for="product in searchProducts.slice(0, 15)"
                :key="product.product_id"
              >
                <router-link
                  class="nav-link"
                  :to="{
                    name: 'productDetails',
                    params: {
                      productSlug: product.slug,
                    },
                  }"
                >
                  <div class="d-flex">
                    <div class="d-inline-block">
                      <img
                        :src="product.thumbnail"
                        :alt="product.product_name"
                        class="src-image pe-2"
                      />
                    </div>
                    <div class="d-inline-block">
                      <p class="mb-0">
                        {{ product.product_name.slice(0, 55) }}
                        {{ product.product_name.length > 55 ? "..." : "" }}
                        <br />
                        <span class="pro-price fw-bold"
                          >{{ product.sale_price.toLocaleString() }} ৳</span
                        >
                        <small class="ps-3" v-if="product.discount > 0"
                          ><del class="text-secondary"
                            >{{
                              (
                                product.original_price - product.sale_price
                              ).toLocaleString()
                            }}
                            ৳</del
                          ></small
                        >
                      </p>
                    </div>
                  </div>
                </router-link>
              </li>
              <li
                v-if="searchProducts.length > 15"
                class="nav-item text-center"
              >
                <router-link
                  class="nav-link btn-primary text-white text-center"
                  :to="'/product-search/' + searchTxt"
                  >See All Result...</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- responsive footer menu -->
    <div class="responsive-footer-menu">
      <ul class="nav d-flex justify-content-evenly">
        <li class="nav-item text-center">
          <router-link to="/offers" class="nav-link text-white px-2">
              <font-awesome icon="gift" class="footer-icon" />
              <p class="text-secondary mb-0">Offers</p>
            </router-link>
        </li>
        <li class="nav-item text-center">
          <a class="nav-link text-white" href="#" @click="compareOpen">
            <font-awesome class="footer-icon" icon="shuffle" />
            <p class="text-secondary mb-0">
              <span>Compare</span>
              (<span class="small" v-if="compareProducts.length">{{ compareProducts.length }}</span>
              <span class="small" v-else>0</span>)
            </p>
          </a>
        </li>
        <li class="nav-item text-center">
          <a class="nav-link text-white" href="#" @click="cartOpen">
            <font-awesome class="footer-icon" icon="cart-arrow-down" />
            <p class="text-secondary mb-0">
              <span>Cart</span>
              (<span class="small" v-if="cartProducts.length">{{ cartProducts.length }}</span>
              <span class="small" v-else>0</span>)
            </p>
          </a>
        </li>
        <li class="nav-item text-center">
          <router-link
              to="/pc-builder"
              class="nav-link text-white">
              <font-awesome class="footer-icon" icon="desktop" />
              <p class="text-secondary mb-0">PC Builder</p>
            </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopHeader",
  props: ["cartProducts", "compareProducts", "userInfo"],
  data() {
    return {
      search: false,
      searchTxt: "",
      windowHeight: window.innerHeight - 60,
      menuShow: false,
      menuOpen: true,
      menuClose: false,
      productCategories: [],
      searchProducts: [],
      logo: '',
      menuPosition: 'inherit',
    };

    // console.log(this.windowHeight);
  },
  methods: {
    searchInShowf() {
      this.search = !this.search;
      this.searchTxt = "";
    },
    menuf() {
      this.menuShow = !this.menuShow;
      this.menuOpen = !this.menuOpen;
      this.menuClose = !this.menuClose;
    },
    cartOpen() {
      this.$emit("OpenCart");
    },
    compareOpen() {
      this.$emit("OpenCompare");
    },
    logout() {
      this.$emit("logout");
    },
    searchClose() {
      this.searchProducts = [];
      this.searchTxt = '';
    },
    SearchEnter() {
      if (this.searchTxt.length) {
        this.searchProducts = [];
        this.$router.push("/product-search/" + this.searchTxt);
      }
    },

    // setting data fetch function
    getSettingData(){
      let setting = this.$store.getters.siteSetting;
      if(setting){
        this.logo = setting.logo;
      }else{
        this.$store.dispatch("getSettingData").then((response) => {
          this.logo = response.data.logo;
          // console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },

    // scroll event for header and footer fixed
    handleScroll () {
      // Any code to be executed when the window is scrolled
      if(window. scrollY > 80){
        // console.log(window.scrollY);
        this.menuPosition = 'fixed';
      }else{
        this.menuPosition = 'inherit';
      }
    }
  },
  created() {
    this.$store
      .dispatch("productCategory")
      .then((response) => {
        this.productCategories = response.data.data;
        // console.log(this.productCategories);
      })
      .catch((error) => {
        console.log(error);
      });

    window.addEventListener('scroll', this.handleScroll);
  },

  // 
  mounted() {
    // call getSettingData for get site setting data
    this.getSettingData();
  },

  unmounted() {
    window.addEventListener('scroll', this.handleScroll);
  },

  watch: {
    searchTxt(txt) {
      if (txt.length) {
        let requestData = {
          page: 1,
          limit: 20,
          searchText: txt,
          order: '',
        };
        this.$store
          .dispatch("SearchProduct", requestData)
          .then((response) => {
            this.searchProducts = response.data.data;
            // console.log(this.searchProducts)
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.searchProducts = [];
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.close-icon{
  background: #ffffff38;
  border-radius: 50%;
  padding: 4px 8px;
}
.cart-compare-area{
  width: 95px;
  display: inline-block;
  position: fixed;
  bottom: 20px;
  right: 0;
  z-index: 999;
}

.compare-area, .cart-area{
  background-color: #932a8f;
  margin: 20px;
  position: relative;
  /* bottom: 20%; */
  width: 50px;
  right: 0;
  text-align: center;
  border-radius: 10px;
}
.pc-builder {
  font-size: 15px;
  background: linear-gradient(#932a8f, #fe344a);
  padding: 7px;
  display: inline-block;
  height: 32px;
  border-radius: 5px;
  margin-left: 12px;
  font-weight: bold;
}
.cl {
  color: #bfbcbc;
}
.top-header,
.menu-area {
  display: block;
}
#header {
  background: #081621;
}
.logoimg {
  height: 50px;
}
.hisize {
  font-size: 20px;
  color: #d94d5c;
}
.count {
  position: absolute;
  top: -10px;
  right: 0px;
  background: #fe344a;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  text-align: center;
  color: #ffff;
}

.menu-area {
  background: white;
  box-shadow: 0 2px 2px rgb(0 0 0 / 10%);
}
.restoggbtn {
  margin-top: -16px;
}
.res-dropmenu {
  min-width: 5em;
}

.responsive-footer-menu{
  position: fixed;
  bottom: 0;
  width: 100%;
  height: auto;
  box-shadow: 0px 5px 5px 7px #081621;
  background-color: #081621;
  left: 0;
  z-index: 999;
}

.responsive-footer-menu .footer-icon{
  font-size: 20px;
}

.responsive-footer-menu .nav-link p{
  font-size: 12px;
}

@media screen and (min-width: 1280px) {
  .search {
    width: 600px;
  }
  .top-menu {
    display: flex;
    float: right;
  }
  .offer {
    font-size: 13px;
    color: #878484;
    line-height: 0;
  }
  .account-li{
    color: #878484;
  }
  .account-li:hover{
    color: #d94d5c;
  }
  .ml {
    margin-left: 15px;
  }
  .responsivemenus, .responsive-footer-menu, .search-result-responsive {
    display: none;
  }

  .big-menu ul ul {
    display: none;
  }

  .big-menu ul li:hover > ul {
    display: block;
    z-index: 1021;
  }

  .big-menu {
    height: 35px;
  }

  .big-menu ul {
    /* background: linear-gradient(top, #efefef 0%, #bbbbbb 100%);  
      background: -moz-linear-gradient(top, #efefef 0%, #bbbbbb 100%); 
      background: -webkit-linear-gradient(top, #efefef 0%,#bbbbbb 100%); 
      box-shadow: 0px 0px 9px rgba(0,0,0,0.15); */
    /* padding: 0 10px; */
    /* border-radius: 10px;   */
    list-style: none;
    position: relative;
    display: inline-table;
  }
  .big-menu ul:after {
    content: "";
    clear: both;
    display: block;
  }
  .big-menu ul li {
    float: left;
  }
  .big-menu ul li a:hover {
    color: #ef4a23;
  }

  .big-menu ul li a {
    text-decoration: none;
    padding: 0 10px 0 10px;
    line-height: 35px;
    white-space: nowrap;
    display: block;
    font-size: 14px;
    font-weight: 600;
    color: #222;
  }

  .big-menu ul ul {
    background: white;
    border-radius: 0px;
    padding: 0;
    position: absolute;
    top: 100%;
    border-top: 2px solid #ef4a23;
    min-width: 200px;
  }
  .big-menu ul ul li {
    float: none;
    position: relative;
  }

  .big-menu ul ul li a {
    color: #222;
    font-size: 14px;
    white-space: normal;
    line-height: 19px;
    padding: 8px 5px;
    font-weight: normal;
  }
  .big-menu ul ul li a:hover {
    background: #ef4a23;
    color: white;
  }

  .big-menu ul ul .right {
    position: absolute;
    left: 100%;
    top: 0;
  }
  .big-menu ul ul .left {
    position: absolute;
    right: 100%;
    top: 0;
  }
}

@media screen and (min-width: 120px) and (max-width: 1279px) {
  .top-header,
  .menu-area {
    display: none;
  }
  .responsive-footer-menu{
    display: block;
  }
  .responsivemenus {
    display: flex;
  }

  .cart-compare-area{
    display: none;
  }
  .search-result {
    display: none;
  }
  .res-menu {
    font-size: 21px;
    color: rgb(255, 255, 255);
    margin-top: 10px;
  }
  .respointer {
    cursor: pointer;
    position: relative;
  }
  .res-count {
    position: absolute;
    top: -17px;
    background: #1648ff;
    border-radius: 50%;
    width: 23px;
    height: 23px;
    text-align: center;
    right: -13px;
  }
  .res-comcount {
    position: absolute;
    top: -17px;
    background: #1648ff;
    border-radius: 50%;
    width: 23px;
    height: 23px;
    text-align: center;
    right: -13px;
  }
  .input-group-text {
    cursor: pointer;
  }
  .responsive-menu-list {
    position: absolute;
    z-index: 1021;
    width: 280px;
    height: auto;
    overflow: scroll;
  }
  .category-menu {
    background: #8f8e8e1f;
  }
  .category-menu ul li a {
    padding-left: 15px;
    padding-right: 0;
  }
  .sub-category-menu {
    background: #e5e3e3;
  }
  .sub-category-menu ul li a {
    padding-left: 25px;
    padding-right: 0;
  }

  .sm-menu ul ul {
    display: none;
  }

  .sm-menu ul li:hover > ul {
    display: block;
    z-index: 1021;
  }

  .sm-menu {
    height: auto;
  }

  .sm-menu ul {
    /* background: linear-gradient(top, #efefef 0%, #bbbbbb 100%);  
      background: -moz-linear-gradient(top, #efefef 0%, #bbbbbb 100%); 
      background: -webkit-linear-gradient(top, #efefef 0%,#bbbbbb 100%); 
      box-shadow: 0px 0px 9px rgba(0,0,0,0.15); */
    /* padding: 0 10px; */
    /* border-radius: 10px;   */
    list-style: none;
    position: relative;
    display: inline-table;
  }
  .sm-menu ul:after {
    content: "";
    clear: both;
    display: block;
  }
  .sm-menu ul li {
    float: left;
    display: block;
    width: 100%;
  }
  .sm-menu ul li a:hover {
    color: #ef4a23;
  }

  .sm-menu ul li a {
    text-decoration: none;
    padding: 0 10px 0 0px;
    line-height: 35px;
    white-space: nowrap;
    display: block;
    font-size: 14px;
    font-weight: 600;
    color: #222;
  }

  .sm-menu ul ul {
    background: #8f8e8e1f;
    border-radius: 0px;
    padding: 0;
    position: relative;
    top: 100%;
    min-width: 200px;
  }
  .sm-menu ul ul li {
    float: none;
    position: relative;
  }

  .sm-menu ul ul li a {
    color: #222;
    font-size: 14px;
    white-space: normal;
    line-height: 19px;
    padding: 4px 10px;
    font-weight: normal;
  }

  .sm-menu ul ul ul li a {
    padding-left: 25px;
  }

  .sm-menu ul ul li a:hover {
    background: #ef4a23;
    color: white;
  }

  .sm-menu ul ul ul {
    position: relative;
    left: 0;
    top: 0;
    background: #6865656b;
  }
}
.pro-price {
  color: #ef4a23;
}
.search-form {
  position: relative;
}
.search-result {
  display: block;
  position: absolute;
  background: white;
  width: 100%;
  /* overflow: scroll; */
  max-height: 400px;
  overflow: auto;
  z-index: 1021;
}
.search-result-responsive {
  display: block;
  position: absolute;
  background: white;
  width: 100%;
  /* overflow: scroll; */
  max-height: 400px;
  overflow: auto;
  z-index: 1021;
  top: 35px;
}
.src-image {
  width: 60px;
}
.nav-item:hover {
  background: #f2f4f8cf;
}
</style>
