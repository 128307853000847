<template>
  <div id="product-detail" class="product-area col-md-12 px-3 mt-2 mb-5">
    <div class="row" v-if="product">
      <div class="col-md-12">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" class="text-decoration-none"
                ><font-awesome icon="home"
              /></a>
            </li>
            <li class="breadcrumb-item" v-if="typeSlug">
              <router-link
                :to="{
                  name: 'typeProducts',
                  params: {
                    typeSlug: typeSlug,
                  },
                }"
                class="text-decoration-none text-capitalize"
                >{{ typeSlug.replace(/-/g, " ") }}</router-link
              >
            </li>
            <li class="breadcrumb-item" v-if="categorySlug">
              <router-link
                :to="{
                  name: 'categoryProducts',
                  params: {
                    typeSlug: typeSlug,
                    categorySlug: categorySlug,
                  },
                }"
                class="text-decoration-none text-capitalize"
                >{{ categorySlug.replace(/-/g, " ") }}</router-link
              >
            </li>
            <li class="breadcrumb-item" v-if="subcategorySlug">
              <router-link
                :to="{
                  name: 'subCategoryProducts',
                  params: {
                    typeSlug: typeSlug,
                    categorySlug: categorySlug,
                    subcategorySlug: subcategorySlug,
                  },
                }"
                class="text-decoration-none text-capitalize"
                >{{ subcategorySlug.replace(/-/g, " ") }}</router-link
              >
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ product.product_name.slice(0, 20)
              }}{{ product.product_name.length > 20 ? "..." : "" }}
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-lg-5 col-md-5 col-12 mb-4 mb-md-0">
        <div class="row bg-white me-1 p-2 rounded">
          <div class="col-md-12 border mb-1 p-1 text-center">
            <img
              :src="product.thumbnail"
              class="img-fluid image"
              :alt="product.product_name"
            />
          </div>
        </div>
        <div class="loupe"></div>
      </div>
      <div class="col-lg-7 col-md-7 col-12 bg-white px-4 py-3 rounded">
        <div class="row price-card">
          <h5 class="nav-link text-start ps-2">{{ product.product_name }}</h5>
          <div class="col-xl-3 col-md-4 col-6 col-sm-6 p-1">
            <div class="py-1 pl-1 price-bg px-1 rounded">
              <span class=" ">Price: </span
              ><span class="text-dark fw-bold"
                >{{ product.sale_price.toLocaleString() }}৳</span
              >
              <del v-if="product.discount > 0"
                >{{ product.original_price.toLocaleString() }}৳</del
              >
            </div>
          </div>

          <div class="col-xl-3 col-md-4 col-6 col-sm-6 p-1">
            <div class="py-1 pl-1 price-bg px-1 rounded">
              <span>Regular Price: </span
              ><span class="text-dark fw-bold"
                >{{ product.regular_price.toLocaleString() }}৳</span
              >
            </div>
          </div>

          <div class="col-xl-3 col-md-4 col-6 col-sm-6 p-1">
            <div class="py-1 pl-1 price-bg px-1 rounded">
              <span>Status:</span>
              <span class="text-dark fw-bold" v-if="product.stock_status == 1"
                >Stock In</span
              >
              <span
                class="text-dark fw-bold"
                v-else-if="product.stock_status == 2"
                >Stock Out</span
              >
              <span class="text-dark fw-bold" v-else>Upcomming</span>
            </div>
          </div>

          <div class="col-xl-3 col-md-4 col-6 col-sm-6 p-1">
            <div class="py-1 pl-1 price-bg px-1 rounded">
              <span>Product Code:</span>
              <span class="text-dark fw-bold">{{ product.product_id }}</span>
            </div>
          </div>

          <div class="col-xl-3 col-md-4 col-6 col-sm-6 p-1">
            <div class="py-1 pl-1 price-bg px-1 rounded">
              <span>Brand:</span>
              <span class="text-dark fw-bold">{{ product.brand_name }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 ps-1">
            <p class="mb-0">Model: {{ product.product_model }}</p>
            <p class="mb-0" v-if="product.warranty > 0">
              Warranty: {{ product.word_warranty }} Months Available
            </p>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12 px-1">
            <div class="d-flex justify-content-start flex-wrap align-items-end">
              <div class="cart-product-quantity mx-1 mb-2">
                <div class="quantity">
                  <label for="" class="d-block mb-0 pb-1">Quantity:</label>
                  <input
                    type="button"
                    value="-"
                    class="minus"
                    @click="decreaseQty"
                  />
                  <input
                    type="text"
                    name="quantity"
                    @keypress="qtyFilter(event)"
                    v-model="cartForm.qty"
                    title="Qty"
                    class="qty text-center"
                    size="2"
                  />
                  <input
                    type="button"
                    value="+"
                    class="plus"
                    @click="increaseQty"
                  />
                </div>
              </div>
              <div v-if="product.variation_type" class="cart-product-quantity mx-1 mb-2">
                <div class="quantity">
                  <label for="" class="d-block mb-0 pb-0"
                    >{{ product.variation_type }}:</label
                  >
                  <select
                    class="form-select"
                    v-model="cartForm.variation"
                    style="height: 34px"
                  >
                    <option value="">
                      Choose {{ product.variation_type }}
                    </option>
                    <option
                      v-for="variation in product.variations"
                      :key="variation.variation_id"
                      :value="
                        variation.variation_id +
                        ';' +
                        variation.variation_name
                      "
                    >
                      {{ variation.variation_name }}
                    </option>
                  </select>
                </div>
              </div>
              <button
                class="btn buybtn-primary mb-2 btn-sm mx-1 border-0 py-2 px-3"
                @click="addToCart(product)"
              >
                <font-awesome icon="cart-arrow-down" /> Add to Cart
              </button>
              <button
                class="btn btn-secondary mb-2 btn-sm mx-1 py-2 px-3 "
                @click="addtoComparef(product)"
              >
                <font-awesome icon="shuffle" class="" /> Add to Compare
              </button>
              <button v-if="product.product_type == 'Budget PC'" class="btn btn-primary mb-2 btn-sm mx-1 border-0 py-2 px-2"
                  @click="addToPcBuilder(product.component_products)">
                  <font-awesome icon="desktop" /> Customize PC
              </button>
            </div>
            <div class="row">
              <div class="col-md-12">
                <hr />
                <div class="product_share">
                  <span>Share With:</span>
                  <a href="#" class="nav-link me-2 d-inline" target="_blank"
                    >Facebook</a
                  >
                  <a href="#" class="nav-link d-inline" target="_blank"
                    >Twittter</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-9 col-md-12 col-sm-12 col-12 mb-4 mb-md-0">
        <div class="row">
          <div class="col-md-12 mt-4">
            <div class="d-flex flex-wrap">
              <a
                class="fw-bold me-1 py-2 px-3 mb-2 speci-btn sactive text-decoration-none"
                href="#specification"
                >Specification</a
              >
              <a
                class="fw-bold me-1 py-2 px-3 mb-2 speci-btn text-decoration-none"
                href="#description"
                >Description</a
              >
              <a
                class="fw-bold me-1 py-2 px-3 mb-2 speci-btn text-decoration-none"
                href="#questions"
                >Questions ({{ product.questions.length }})</a
              >
              <a
                class="fw-bold py-2 px-3 mb-2 speci-btn text-decoration-none"
                href="#reviews"
                >Reviews ({{ product.reviews.length }})</a
              >
            </div>
          </div>
          <div class="col-md-12 mt-3" id="specification">
            <h5 class="mt-2 mx-1 bg-white pb-3">
              <span class="d-inline-block ps-4 py-3 pb-4"></span> Specification
            </h5>
            <div
              class="mx-1 bg-white px-3 pb-4"
              v-html="product.specification"
            ></div>
          </div>
          <div class="col-md-12 mt-3" id="description">
            <h5 class="mt-2 mx-1 bg-white pb-3 mb-0">
              <span class="d-inline-block ps-3 py-3 pb-4"></span> Description
            </h5>
            <div
              class="mx-1 bg-white px-4 pb-4"
              v-html="product.description"
            ></div>
          </div>
          <div class="col-md-12 mt-3" id="questions">
            <h5 class="mt-2 mx-1 bg-white mb-0">
              <span class="d-inline-block ps-3 py-3"
                >Questions ({{ product.questions.length }})</span
              >
              <span
                class="d-inline-block me-3 py-1 mt-2 btn buybtn-primary float-end"
                @click="QueRevModal('question')"
                >Ask Question</span
              >
            </h5>
            <div
              class="mx-1 bg-white px-4 py-2 ques-loop rounded mt-1"
              v-for="question in product.questions.slice(0, 10)"
              :key="question.question_id"
            >
              <div class="ques">
                <div class="qtitle">Q:</div>
                <div class="qdetail w-100">
                  <p class="mb-0">
                    {{ question.question }}
                  </p>
                  <div class="text-end">
                    <small class="fw-normal text-dark"
                      >By {{ question.sender_name }}</small
                    >
                    <span class="fw-normal fst-italic"
                      ><small
                        >on
                        {{
                          monthNames[new Date(question.created_at).getMonth()]
                        }}
                        {{ new Date(question.created_at).getDate() }},
                        {{ new Date(question.created_at).getFullYear() }}</small
                      ></span
                    >
                  </div>
                </div>
              </div>
              <div class="ques">
                <div class="qtitle mt-2">A:</div>
                <div class="qdetail mt-2 w-100">
                  <p>{{ question.answare }}</p>
                  <div v-if="question.answare" class="text-end">
                    <small class="fw-normal">By Sopmax</small>
                  </div>
                </div>
              </div>
            </div>
            <p
              v-if="product.reviews.length > 2"
              class="nav-link text-end my-0 mx-1 bg-white"
            >
              <router-link
                :to="{
                  name: 'ProductQuestions',
                  params: {
                    productSlug: product.slug,
                    productId: product.product_id,
                  },
                }"
                class="nav-link"
                target="_blank"
                >More questions...</router-link
              >
            </p>
            <div v-if="!product.questions.length" class="col-md-12">
              <p class="fw-bold text-center pb-3 mx-1 bg-white pt-2 border-top">
                There are no questions asked yet.
              </p>
            </div>
          </div>
          <div class="col-md-12 mt-3" id="reviews">
            <h5 class="mt-2 mx-1 bg-white mb-0">
              <span class="d-inline-block ps-3 py-3"
                >Reviews ({{ product.reviews.length }})</span
              >
              <span
                class="d-inline-block me-3 py-1 mt-2 btn buybtn-primary float-end"
                @click="QueRevModal('review')"
                >Write a Review</span
              >
              <span
                class="d-block mb-2 py-3 px-3"
                v-if="product.reviews.length"
              >
                <font-awesome
                  icon="star"
                  v-for="n in parseInt(
                    product.total_rating / product.total_review
                  )"
                  :key="n"
                  class="text-success"
                  :class="n"
                />
                &nbsp;{{
                  (product.total_rating / product.total_review).toFixed(2)
                }}
                out of 5
              </span>
            </h5>
            <div
              class="mx-1 bg-white px-4 py-2 fw-bold ques-loop rounded mt-1"
              v-for="review in product.reviews.slice(0, 10)"
              :key="review.review_id"
            >
              <div>
                <div class="d-flex justify-content-between">
                  <div>
                    <font-awesome
                      icon="star"
                      v-for="n in parseInt(review.rating)"
                      :key="n"
                      class="text-success"
                      :class="n"
                    />
                  </div>
                  <div>
                    <small class="fw-normal text-dark fst-italic"
                      >By {{ review.reviewer_name }}</small
                    >
                    <span class="fw-normal fst-italic"
                      ><small
                        >on
                        {{ monthNames[new Date(review.created_at).getMonth()] }}
                        {{ new Date(review.created_at).getDate() }},
                        {{ new Date(review.created_at).getFullYear() }}</small
                      ></span
                    >
                  </div>
                </div>
                <div class="pt-2 fw-normal">{{ review.message }}</div>
              </div>
            </div>
            <p
              v-if="product.reviews.length > 2"
              class="nav-link text-end my-0 mx-1 bg-white"
            >
              <router-link
                :to="{
                  name: 'ProductReviews',
                  params: {
                    productSlug: product.slug,
                    productId: product.product_id,
                  },
                }"
                class="nav-link"
                target="_blank"
                >More reviews...</router-link
              >
            </p>
          </div>
          <div v-if="!product.reviews.length" class="col-md-12">
            <p class="fw-bold text-center pb-3 mx-1 bg-white pt-2 border-top">
              This product has no reviews yet.
            </p>
          </div>
        </div>
      </div>
      <div
        class="col-lg-3 col-md-12 col-sm-12 mt-4 col-12 mb-4 mb-md-0 mt-lg-5"
      >
        <div class="row d-flex flex-row mt-lg-4">
          <div
            class="col-lg-12 col-md-3 col-sm-4 col-12 mb-4"
            v-for="rproduct in relatedProducts"
            :key="rproduct.product_id"
          >
            <div class="pro-card-inner">
              <div class="marks" v-if="rproduct.discount > 0">
                <span class="mark"
                  >Save:
                  {{
                    (
                      rproduct.original_price - rproduct.sale_price
                    ).toLocaleString()
                  }}৳</span
                >
              </div>
              <div class="pro-image">
                <router-link
                  :to="{
                    name: 'productDetails',
                    params: {
                      productSlug: rproduct.slug,
                      typeSlug: typeSlug,
                      categorySlug: categorySlug,
                      subcategorySlug: subcategorySlug,
                    },
                  }"
                >
                  <img
                    v-if="rproduct.thumbnail"
                    :src="rproduct.thumbnail"
                    :alt="rproduct.product_name"
                    class="thumble"
                  />
                  <img
                    v-else
                    src="@/assets/product-default.jpeg"
                    :alt="rproduct.product_name"
                    class="thumble"
                  />
                </router-link>
              </div>
              <div class="pro-details">
                <h4 class="pro-name">
                  <router-link
                    :to="{
                      name: 'productDetails',
                      params: {
                        productSlug: rproduct.slug,
                        typeSlug: typeSlug,
                        categorySlug: categorySlug,
                        subcategorySlug: subcategorySlug,
                      },
                    }"
                    class="nav-link p-0 text-start"
                    >{{ rproduct.product_name }}</router-link
                  >
                </h4>
              </div>
              <div class="justify-content-center buy-btn">
                <div class="pro-price py-2 text-center pb-3">
                  {{ rproduct.sale_price.toLocaleString() }}৳
                  <small class="ml-3" v-if="rproduct.discount > 0"
                    ><del class="text-secondary"
                      >{{
                        (
                          rproduct.original_price - rproduct.sale_price
                        ).toLocaleString()
                      }}৳</del
                    ></small
                  >
                </div>
                <button
                  class="btn btn-out mb-2 btn-sm float-start py-2 col-12"
                  v-if="rproduct.stock_status == 2"
                >
                  Stock Out
                </button>
                <button
                  class="btn btn-out mb-2 btn-sm float-start py-2 col-12"
                  v-else-if="rproduct.stock_status == 3"
                >
                  Up Coming
                </button>
                <div v-else>
                  <router-link
                    class="btn btn-primary mb-2 btn-sm float-start col-12 py-2 border-0"
                    :to="{
                      name: 'productDetails',
                      params: {
                        productSlug: rproduct.slug,
                        typeSlug: typeSlug,
                        categorySlug: categorySlug,
                        subcategorySlug: subcategorySlug,
                    },
                  }"
                  >
                    <font-awesome icon="cart-arrow-down" /> Buy Now
                  </router-link>
                </div>
                <button
                  class="btn btn-secondary mb-1 btn-sm float-end col-12 py-2"
                  @click="addtoComparef(rproduct)"
                >
                  <font-awesome icon="shuffle" class="" /> Add to Compare
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- comment or question modal -->
    <div
      class="modal fade"
      :class="commentModalShow"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h6
              class="modal-title fw-bold text-capitalize"
              id="staticBackdropLabel"
            >
              Write {{ modalTitle }}
            </h6>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="commentModalClose()"
            ></button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="pro" class="fw-bold mb-0">Product</label>
              <input
                v-if="product"
                type="text"
                id="pro"
                class="form-control"
                readonly
                :value="product.product_name"
              />
            </div>
            <div class="form-group mt-3" v-if="modalTitle == 'review'">
              <label for="pro" class="fw-bold mb-0"
                >Rating <small class="text-danger">*</small></label
              >
              <div class="d-block">
                <font-awesome
                  icon="star"
                  :class="one"
                  class="me-3"
                  @click="star(1)"
                />
                <font-awesome
                  icon="star"
                  :class="tow"
                  class="me-3"
                  @click="star(2)"
                />
                <font-awesome
                  icon="star"
                  :class="three"
                  class="me-3"
                  @click="star(3)"
                />
                <font-awesome
                  icon="star"
                  :class="four"
                  class="me-3"
                  @click="star(4)"
                />
                <font-awesome
                  icon="star"
                  :class="five"
                  class=""
                  @click="star(5)"
                />
              </div>
            </div>
            <div class="form-group mt-3">
              <label for="quRe" class="text-capitalize mb-0 fw-bold"
                >{{ modalTitle }} <small class="text-danger">*</small></label
              >
              <textarea
                name=""
                id=""
                cols="30"
                class="form-control"
                rows="5"
                :placeholder="'Write your ' + modalTitle"
                v-model="form.msg"
              ></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              @click="msgSubmit()"
            >
              Submit
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="commentModalClose()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- login modal -->
    <div
      class="modal fade"
      :class="loginModal"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered loginmodal">
        <div class="modal-content">
          <div class="modal-header">
            <h6
              class="modal-title fw-bold text-capitalize"
              id="staticBackdropLabel"
            >
              Account Login
            </h6>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="loginModalClose()"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/" class="text-decoration-none"
                        ><font-awesome icon="home"
                      /></router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <a href="#" class="text-decoration-none text-capitalize"
                        >Account Login</a
                      >
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <form class="login-area d-block mx-auto border rounded">
              <div class="form-group row mx-0">
                <div
                  class="col-6 py-2 text-center log"
                  :class="mobile ? 'activelog' : ''"
                  @click="loginType"
                >
                  Login With Mobile
                </div>
                <div
                  class="col-6 py-2 text-center log"
                  :class="!mobile ? 'activelog' : ''"
                  @click="loginType"
                >
                  Login With E-mail
                </div>
              </div>
              <div class="p-3">
                <div class="form-group mt-3">
                  <label for="" class=""
                    >{{ mobile ? "Mobile" : "E-mail" }}:</label
                  >
                  <input
                    type="text"
                    v-model="loginForm.user_name"
                    class="form-control"
                    :class="mobile ? 'ps-5' : ''"
                    :placeholder="
                      mobile ? 'Enter mobile number' : 'Enter e-mail address'
                    "
                  />
                  <span v-if="mobile" class="mobile">+88</span>
                </div>
                <div class="form-group mt-3">
                  <label for="" class="d-block"
                    >Password:
                    <a class="float-end nav-link p-0 forgot d-inline-block"
                      >Forgot Password?</a
                    ></label
                  >
                  <input
                    type="password"
                    v-model="loginForm.password"
                    class="form-control"
                    placeholder="Enter password"
                  />
                </div>
                <div class="form-group row">
                  <div class="col-6 mt-3">
                    <button type="reset" class="btn btn-primary col-12">
                      Clear
                    </button>
                  </div>
                  <div class="col-6 mt-3">
                    <button
                      type="button"
                      class="btn btn-primary col-12"
                      @click="login"
                    >
                      Login
                    </button>
                  </div>
                </div>
                <div class="form-group mt-4 text-center">
                  <p>Don't have an account?</p>
                </div>
                <div class="form-group">
                  <router-link to="/register" class="btn btn-primary col-12"
                    >Create New Account</router-link
                  >
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="loginModalClose()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- alert modal  -->
    <ProductAddAlert
      :modalAlert="addAlert"
      :alertMsg="alertMsg"
      @modalClose="modalClose"
    />

    <!-- loading view -->
    <LoadingView v-if="showLoading"/>
  </div>
</template>
<script>
import ProductAddAlert from "@/components/ProductAddAlert.vue";
import LoadingView from '@/components/Layouts/LoadingView.vue';
import { reactive, watch, computed, ref } from 'vue';
import { useHead } from "@vueuse/head";

export default {
  name: "ProductDetailView",
  props: ["imageBaseUrl", "userInfo"],
  components: { ProductAddAlert, LoadingView },

  // meta tag setup start
  setup() {
    // Define reactive properties within the setup function
    const siteTitle = ref('siteTitle');
    const siteData = reactive({
      title: siteTitle.value +' Price in Bangladesh | Sopma Tech',
      description: "Sopma Tech is the Best Laptop, Computer, Gaming PC, Component, Accessories, and Gadget retail &amp; Online shop in Bangladesh. Sopma Tech offers the Latest Tech products with the most competitive price in BD.",
      keywords: "Laptop shop in Bangladesh, Laptop shop in bd, computer shop in Bangladesh, PC shop in Bangladesh, computer shop in BD, Gaming PC shop in Bangladesh, PC accessories shop in Bangladesh, best computer shop in Bangladesh, Gadget shop in bd, Gadget Shop in Bangladesh, Online Shop in BD, online computer shop in bd, computer accessories online shop in Bangladesh, computer parts shop in bd, Laptop in Bangladesh, Notebook, Laptop, Desktop, Brand PC, computer, computer store Bangladesh, laptop store Bangladesh, gaming, desktop, monitor, Sopma Tech, computer accessories, Desktop accessories, Laptop accessories, Laptop Online Store in BD, adata, apacer, apple, asus, bangladesh, baseus, belkin, benq, best, boya, brother, cable, camera, canon, GPU, graphics card, Sopma Tech &amp; Engineering Ltd, SOPMATECH, laptop price, laptop price in bangladesh, laptop price bd,",
    });

    // Watch for changes in siteTitle and update siteData.title accordingly
    watch(siteTitle, (newSiteTitle) => {
      siteData.title = newSiteTitle +' Price in Bangladesh | Sopma Tech'
    });

    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description),
        },
        {
          name: 'keywords',
          content: computed(() => siteData.keywords),
        },
      ],
    });

    // Access siteTitle directly in setup
    return {
      siteTitle,
    };
  },
  // meta tag setup end

  data() {
    return {
      showLoading: true,
      addAlert: "",
      alertMsg: "",
      loading: false,
      productSlug: this.$route.params.productSlug,
      typeSlug: this.$route.params.typeSlug,
      categorySlug: this.$route.params.categorySlug,
      subcategorySlug: this.$route.params.subcategorySlug,
      productImages: "",
      product: "",
      monthNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      sizes: [],
      cartForm: {
        variation: "",
        qty: 1,
        product: "",
      },
      relatedProducts: [],
      form: {
        msg: "",
        rating: 0,
      },
      commentModalShow: "",
      loginModal: "",
      modalTitle: "",
      one: "",
      tow: "",
      three: "",
      four: "",
      five: "",
      mobile: true,
      loginForm: {
        user_name: "",
        mobile: "",
        email: "",
        password: "",
      },
    };
  },
  methods: {
    qtyFilter(evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      if (!/^[-+]?[0-9]*?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    decreaseQty() {
      if (this.cartForm.qty > 1) {
        this.cartForm.qty -= 1;
      }
    },
    increaseQty() {
      this.cartForm.qty += 1;
    },
    addtoComparef(product) {
      this.$store.dispatch("addtoCompare", product);
    },
    addToCart(product) {
      if (this.cartForm.qty == 0 || this.cartForm.qty == "") {
        this.cartForm.qty = 1;
      }
      this.cartForm.product = product;
      if (product.variation_type && this.cartForm.variation == "") {
        this.$swal({
          title: "REQUIRED!",
          icon: "warning",
          text: "Product " + product.variation_type + " is required!",
        });
      } else {
        let variation = this.cartForm.variation.split(";");
        this.cartForm.variation_id = variation[0];
        this.cartForm.variation_name = variation[1];
        this.cartForm.cartId = product.product_id + variation[0];
        this.$store.dispatch("addToCart", this.cartForm);
        this.alertMsg = product.product_name;
        this.addAlert = "show d-block";
      }
    },

    // product add to pc builder function
    addToPcBuilder(components) {
      this.$store
        .dispatch("componentAddToPcBuilder", components)
        .then((response) => {
          this.$router.push("/pc-builder");
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    modalClose() {
      this.addAlert = "";
    },
    // color change function
    colorf(event) {
      console.log(event.target.value);
    },
    QueRevModal(val) {
      this.modalTitle = val;
      this.one = "";
      this.tow = "";
      this.three = "";
      this.four = "";
      this.five = "";
      this.form.rating = 0;
      this.form.msg = "";
      if (this.userInfo) {
        this.commentModalShow = "show d-block";
      } else {
        this.loginModal = "show d-block";
      }
    },
    commentModalClose() {
      this.commentModalShow = "";
    },
    loginModalClose() {
      this.loginModal = "";
    },
    star(id) {
      if (id == 1) {
        this.one = "text-success";
        this.tow = "";
        this.three = "";
        this.four = "";
        this.five = "";
      } else if (id == 2) {
        this.one = "text-success";
        this.tow = "text-success";
        this.three = "";
        this.four = "";
        this.five = "";
      } else if (id == 3) {
        this.one = "text-success";
        this.tow = "text-success";
        this.three = "text-success";
        this.four = "";
        this.five = "";
      } else if (id == 4) {
        this.one = "text-success";
        this.tow = "text-success";
        this.three = "text-success";
        this.four = "text-success";
        this.five = "";
      } else if (id == 5) {
        this.one = "text-success";
        this.tow = "text-success";
        this.three = "text-success";
        this.four = "text-success";
        this.five = "text-success";
      }
      this.form.rating = id;
    },
    loginType() {
      this.mobile = !this.mobile;
      this.loginForm.user_name = "";
      this.loginForm.mobile = "";
      this.loginForm.email = "";
      this.loginForm.password = "";
    },
    login() {
      var error = "";
      if (this.mobile) {
        const mobExp = /^[0-9]*$/;
        this.loginForm.mobile = this.loginForm.user_name;
        if (this.loginForm.mobile == "") {
          error += '<p class="text-danger mb-0">Mobile number is required!</p>';
        } else if (mobExp.test(this.loginForm.mobile) == false) {
          error += '<p class="text-danger mb-0">Only number is allowed!</p>';
        } else if (
          this.loginForm.mobile.length < 11 ||
          this.loginForm.mobile.length > 11
        ) {
          error += '<p class="text-danger mb-0">Mobile number is invalid!</p>';
        }
      } else {
        const emailRegex = RegExp(
          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        );
        this.loginForm.email = this.loginForm.user_name;
        if (this.loginForm.email == "") {
          error += '<p class="text-danger mb-0">E-mail is required!</p>';
        } else if (!emailRegex.test(this.loginForm.email)) {
          error += '<p class="text-danger mb-0">E-mail is invalid!</p>';
        }
      }
      if (this.loginForm.password == "") {
        error += '<p class="text-danger mb-0">Password is required!';
      } else if (this.loginForm.password.length < 6) {
        error +=
          '<p class="text-danger mb-0">Enter at least 6 digits password!</p>';
      }
      if (error != "") {
        this.$swal({
          title: "REQUIRED!",
          icon: "error",
          html: error,
        });
      } else {
        this.$store
          .dispatch("UserLogin", this.loginForm)
          .then((response) => {
            if (response.status == 200) {
              this.$emit("loginReload");
              this.commentModalShow = "show d-block";
              this.loginModal = "";
            } else {
              var error =
                '<p class="text-danger mb-0">' + response.data + "</p>";
              this.$swal({
                title: "Warning!",
                icon: "error",
                html: error,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    msgSubmit() {
      var error = "";
      if (this.modalTitle == "review") {
        let reviewData = {
          product_id: this.product.product_id,
          message: this.form.msg,
          rating: this.form.rating,
        };
        if (reviewData.message == "") {
          error += '<p class="text-danger mb-0">Review is required!</p>';
        }
        if (reviewData.rating == 0) {
          error += '<p class="text-danger mb-0">Rating is required!</p>';
        }
        if (error.length) {
          this.$swal({
            title: "Warning!",
            icon: "error",
            html: error,
          });
        } else {
          this.$store
            .dispatch("reviewStore", reviewData)
            .then((response) => {
              if (response.status == 201) {
                this.commentModalShow = "";
                this.$swal({
                  title: "success!",
                  icon: "success",
                  text: response.data.message,
                });
              } else {
                this.$swal({
                  title: "Warning!",
                  icon: "error",
                  text: response.data.data,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        if (this.form.msg.length) {
          let questionData = {
            product_id: this.product.product_id,
            question: this.form.msg,
          };

          this.$store
            .dispatch("questionStore", questionData)
            .then((response) => {
              if (response.status == 201) {
                this.commentModalShow = "";
                this.$swal({
                  title: "success!",
                  icon: "success",
                  text: response.data.message,
                });
              } else {
                this.$swal({
                  title: "Warning!",
                  icon: "error",
                  text: response.data.data,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          error += '<p class="text-danger mb-0">Question is required!</p>';
          this.$swal({
            title: "Warning!",
            icon: "error",
            html: error,
          });
        }
      }
    },

    // get product detail
    getProductDetails() {
      // on page loading
      this.showLoading = true;
      this.$store
        .dispatch("productDetailf", this.productSlug)
        .then((response) => {
          this.product = response.data.data.product;
          this.relatedProducts = response.data.data.relatedProducts;
          this.cartForm.qty = 1;
          this.cartForm.product = "";
          this.siteTitle = this.product.product_name;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          // off page loading
          this.showLoading = false;
        });
    },
  },
  created() {
    this.siteTitle = this.$ucWords(this.productSlug.replace(/-/g, " "));
    this.getProductDetails();
  },
  watch: {
    $route() {
      if (this.$route.name == "productDetails") {
        this.productSlug = this.$route.params.productSlug;
        this.typeSlug = this.$route.params.typeSlug;
        this.categorySlug = this.$route.params.categorySlug;
        this.subcategorySlug = this.$route.params.subcategorySlug;
        this.getProductDetails();
      }
    },
  },
};
</script>
<style scoped>
.gallary-img {
  height: 50px;
  width: 50px;
  border: 1px solid lightgray;
  padding: 5px;
  margin: 1px;
}
.price-bg {
  background: #f1f1f3d1;
}
.buybtn-primary {
  background: #d3c7db;
  color: #932a8f;
  font-weight: bold;
  transition: 1s;
}
.buybtn-primary:hover {
  background-color: #932a8f;
  box-shadow: 0px 3px 3px 1px #f2f4f8;
  color: #fffcfccc;
  transition: 1s;
}

.loupe {
  display: none;
  position: absolute;
  width: 400px;
  height: 400px;
  border: 1px solid black;
  box-shadow: 5px 5px 12px black;
  background: rgba(0, 0, 0, 0.25);
  cursor: crosshair;
  overflow: hidden;
  right: 0;
  top: 0;
}
.loupe img {
  position: absolute;
  right: 0;
}
.speci-btn {
  border: none;
  border-radius: 5px;
  background: white;
  font-size: 13px;
}
.sactive {
  background: #293a99;
  color: white;
}
.speci-btn:hover {
  background: #293a99;
  color: white;
}
.data-table {
  width: 100%;
}
.ques-loop {
  display: block;
  height: auto;
}
.ques {
  width: 100%;
  display: flex;
  height: auto;
}
.qtitle {
  width: 30px;
  float: left;
  height: auto;
}
.qdetail {
  width: auto;
  float: right;
}
.login-area {
  width: 400px;
  background: #293a99;
  box-shadow: 0px 5px 10px 1px gray;
  color: white;
}
.forgot {
  color: white;
}
.forgot:hover {
  color: #adb5bd;
  cursor: pointer;
}
.log {
  border-bottom: 1px solid white;
  cursor: pointer;
}
.activelog {
  background: #02255a;
  border-bottom: none;
}
.mobile {
  position: relative;
  top: -30px;
  background: #f2f4f8;
  color: black;
  padding: 5px;
  left: 5px;
}
.loginmodal {
  width: 450px;
}

.thumble {
  height: 221px;
  width: 221px;
  text-align: center;
}
.pro-card-area {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  padding: 0;
  justify-content: flex-start;
  background: none;
}

.pro-card {
  flex: 0 0 20%;
  max-width: 100%;
  padding: 0 5px 10px;
  margin-bottom: 0;
  display: flex;
  position: relative;
}
.pro-card-inner {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
}
.pro-card-inner:hover {
  box-shadow: 0px 0px 8px 1px gray;
}
.marks {
  display: flex;
  position: absolute;
  top: 5px;
  left: 0;
  z-index: 10;
  flex-direction: column;
  align-items: flex-start;
}
.mark {
  background: #ed283a;
  width: auto;
  color: #fff;
  font-size: 12px;
  padding: 3px 10px;
  line-height: 14px;
  margin-bottom: 2px;
  border-radius: 0 20px 20px 0;
  flex: 0 0 auto;
}

.pro-image {
  text-align: center;
  border-bottom: 3px solid rgba(55, 73, 187, 0.03);
  flex: 0 0 220px;
  padding: 20px;
  margin: 0;
}
.pro-image img {
  max-width: 100%;
}
.pro-details {
  padding: 10px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  border-bottom: 3px solid rgba(55, 73, 187, 0.03);
}
.price-card {
  font-size: 14px;
}
.pro-name {
  font-weight: 400;
  font-size: 15px;
}
.pro-price {
  line-height: 22px;
  font-size: 17px;
  font-weight: 600;
  color: #ef4a23;
}
.text-dark {
  color: black;
}
.buy-btn {
  padding: 10px;
}
.btn-primary {
  background: #f2f4f8;
  color: #932a8f;
  font-weight: bold;
  transition: 1s;
}
.btn-out {
  background: #d4d5d7;
  color: #932aaa;
  font-weight: bold;
}
.btn-primary:hover {
  background-color: #932a8f;
  box-shadow: 0px 3px 3px 1px #f2f4f8;
  color: #fffcfccc;
  transition: 1s;
}
.btn-secondary{
  background-color: #f2f4f86b;
  transition: 1s;
  font-weight: 500;
  border: 0;
  color: rgb(0, 0, 0);
}

.btn-secondary:hover {
  box-shadow: 0px 3px 3px 1px #f2f4f8;
  color: #932a8f;
  background-color: #cacdd5;
}
.gallary-img {
  height: 50px;
  width: 50px;
  border: 1px solid lightgray;
  padding: 5px;
  margin: 1px;
}
.price-bg {
  background: #f1f1f3d1;
}

@media (max-width: 1279px) {
  .pro-card {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (max-width: 767px) {
  .pro-card {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
</style>