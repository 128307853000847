<template>
  <div id="offer" class="col-md-12 px-3 mt-2 mb-5">
    <div class="row">
      <div class="col-md-12">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/" class="text-decoration-none"
                ><font-awesome icon="home"
              /></router-link>
            </li>
            <li class="breadcrumb-item">
              <a href="#" class="text-decoration-none text-capitalize"
                >PC Builder</a
              >
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 px-4 mb-0" v-if="errorMsg.length">
        <div class="alert alert-warning alert-dismissible fade show" role="alert">
          <p v-for="(msg, index) in errorMsg" :key="index">{{ msg }}</p>
        </div>
      </div>
      <div class="col-md-12 p-5">
        <div class="row row bg-white rounded p-3">        
          <div class="col-md-12 px-5 text-end">            
            <div class="d-inline-block budgetPcInput">
              <div class="input-group">
                <input type="text" :value="pcBuilderComponentCart.totalAmount" class="form-control py-1" aria-describedby="budgetPc">
                <router-link :to="{
                  name: 'BudgetPc',
                  params: {
                    maxAmount: pcBuilderComponentCart.totalAmount,
                  },
                }" class="input-group-text btn btn-primary btn-sm" id="budgetPc"><font-awesome icon="desktop" class="hisize" /> Budget PC</router-link>
              </div>
            </div>
            <button class="btn btn-primary btn-sm ms-1" @click="addToCart"><font-awesome icon="cart-arrow-down" class="hisize" /> Add to Cart</button>
            <button @click="savePc" class="btn btn-primary btn-sm ms-1"><font-awesome icon="floppy-disk" class="hisize" /> Save PC</button>
              <!-- <button class="btn btn-primary ms-1">Print</button> -->
          </div>
          
          <div class="col-md-12 px-5">
            <!-- core component section -->
            <h5 class="component-bg mt-2 rounded py-2 px-3">Core Components</h5>
            <table class="table">
              <tbody class="border-0" v-for="component in coreComponents" :key="component.id">
                <template v-if="pcBuilderComponentCart['componentId'+component.category.id]" >
                  <tr v-for="(item, index) in pcBuilderComponentCart['componentId'+component.category.id].products" :key="item.product.id">
                    <td class="component-img">
                      <img :src="item.product.thumbnail" width="50" height="50">
                    </td>
                    <td class="component-name">
                      <p>
                        <span class="fw-bold">{{ component.category.category_name }}</span>
                        <span class="badge bg-danger italic ms-2" v-if="component.required"><small>Required</small></span> 
                      </p>
                      <p class="component-product-name">{{ item.product.product_name }}</p>
                      <router-link :to ="{
                            name: 'PcBuilderComponets',
                            params: {
                              maxQty: component.max_qty,
                              componentId: component.category.id,
                              slug: component.category.slug,
                            },
                          }" class="btn btn-sm p-1 add-more btn-primary" v-if="(component.max_qty>1) && (pcBuilderComponentCart['componentId'+component.category.id].products.length < component.max_qty) && (index === pcBuilderComponentCart['componentId'+component.category.id].products.length - 1)" ><font-awesome icon="plus" /> Add Another {{ component.category.category_name }}</router-link>
                    </td>
                    <td class="price">{{ item.product.sale_price.toLocaleString() }}</td>
                    <td class="action">
                      <template v-if="pcBuilderComponentCart['componentId'+component.category.id].multiple">
                        <span class="py-1  px-2 pc-icon cr-p" v-on:click="removePcComponent(component.category.id,item.id)"><font-awesome icon="close" /></span>
                        <router-link :to ="{
                            name: 'PcBuilderComponets',
                            params: {
                              maxQty: component.max_qty,
                              componentId: component.category.id,
                              slug: component.category.slug,
                              id: item.id
                            },
                          }" ><span class="py-1  px-2 pc-icon"><font-awesome icon="rotate" /></span></router-link>
                      </template>
                      <template v-else>
                        <span class="py-1  px-2 pc-icon cr-p" v-on:click="removePcComponent(component.category.id)"><font-awesome icon="close" /></span>
                        <router-link :to ="{
                            name: 'PcBuilderComponets',
                            params: {
                              maxQty: component.max_qty,
                              componentId: component.category.id,
                              slug: component.category.slug,
                            },
                          }" ><span class="py-1  px-2 pc-icon"><font-awesome icon="rotate" /></span></router-link>
                      </template>                      
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <td class="component-img">
                    <font-awesome icon="image" />
                  </td>
                  <td class="component-name">
                    <p>
                      <span class="fw-bold">{{ component.category.category_name }}</span>
                      <span class="badge bg-danger italic ms-2" v-if="component.required"><small>Required</small></span> 
                    </p>
                    <p class="component-product-name"></p>
                  </td>
                  <td class="price"></td>
                  <td class="action">
                    <router-link :to ="{
                        name: 'PcBuilderComponets',
                        params: {
                          maxQty: component.max_qty,
                          componentId: component.category.id,
                          slug: component.category.slug,
                        },
                      }" class="btn btn-outline-primary py-1">Choose</router-link>
                  </td>
                </tr>
              </tbody>
            </table>
            
            <!-- peripharals component section -->
            <h5 class="component-bg mt-2 rounded py-2 px-3">Peripherals & Others</h5>
            <table class="table">
              <tbody class="border-0" v-for="componentPer in peripheralsComponents" :key="componentPer.id">
                <template v-if="pcBuilderComponentCart['componentId'+componentPer.category.id]" >
                  <tr v-for="(item, index) in pcBuilderComponentCart['componentId'+componentPer.category.id].products" :key="item.product.id">
                    <td class="component-img">
                      <img :src="item.product.thumbnail" width="50" height="50">
                    </td>
                    <td class="component-name">
                      <p>
                        <span class="fw-bold">{{ componentPer.category.category_name }}</span>
                        <span class="badge bg-danger italic ms-2" v-if="componentPer.required"><small>Required</small></span> 
                      </p>
                      <p class="component-product-name">{{ item.product.product_name }}</p>
                      <router-link :to ="{
                            name: 'PcBuilderComponets',
                            params: {
                              maxQty: componentPer.max_qty,
                              componentId: componentPer.category.id,
                              slug: componentPer.category.slug,
                            },
                          }" class="btn btn-sm p-1 add-more btn-primary" v-if="(componentPer.max_qty>1) && (pcBuilderComponentCart['componentId'+componentPer.category.id].products.length < componentPer.max_qty) && (index === pcBuilderComponentCart['componentId'+componentPer.category.id].products.length - 1)" ><font-awesome icon="plus" /> Add Another {{ componentPer.category.category_name }}</router-link>
                    </td>
                    <td class="price">{{ item.product.sale_price.toLocaleString() }}</td>
                    <td class="action">
                      <template v-if="pcBuilderComponentCart['componentId'+componentPer.category.id].multiple">
                        <span class="py-1  px-2 pc-icon cr-p" v-on:click="removePcComponent(componentPer.category.id,item.id)"><font-awesome icon="close" /></span>
                        <router-link :to ="{
                            name: 'PcBuilderComponets',
                            params: {
                              maxQty: componentPer.max_qty,
                              componentId: componentPer.category.id,
                              slug: componentPer.category.slug,
                              id: item.id
                            },
                          }" ><span class="py-1  px-2 pc-icon"><font-awesome icon="rotate" /></span></router-link>
                      </template>
                      <template v-else>
                        <span class="py-1  px-2 pc-icon cr-p" v-on:click="removePcComponent(componentPer.category.id)"><font-awesome icon="close" /></span>
                        <router-link :to ="{
                            name: 'PcBuilderComponets',
                            params: {
                              maxQty: componentPer.max_qty,
                              componentId: componentPer.category.id,
                              slug: componentPer.category.slug,
                            },
                          }" ><span class="py-1  px-2 pc-icon"><font-awesome icon="rotate" /></span></router-link>
                      </template>                      
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <td class="component-img">
                    <font-awesome icon="image" />
                  </td>
                  <td class="component-name">
                    <p>
                      <span class="fw-bold">{{ componentPer.category.category_name }}</span>
                      <span class="badge bg-danger italic ms-2" v-if="componentPer.required"><small>Required</small></span> 
                    </p>
                    <p class="component-product-name"></p>
                  </td>
                  <td class="price"></td>
                  <td class="action">
                    <router-link :to ="{
                        name: 'PcBuilderComponets',
                        params: {
                          maxQty: componentPer.max_qty,
                          componentId: componentPer.category.id,
                          slug: componentPer.category.slug,
                        },
                      }" class="btn btn-outline-primary py-1">Choose</router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- alert modal  -->
    <ProductAddAlert
      :modalAlert="addAlert"
      :alertMsg="alertMsg"
      @modalClose="modalClose"
    />

    <!-- loading view -->
    <LoadingView v-if="showLoading"/> 

  </div>
</template>
<script>
import ProductAddAlert from "@/components/ProductAddAlert.vue";
import LoadingView from '@/components/Layouts/LoadingView.vue';
import { reactive, computed } from 'vue';
import { useHead } from "@vueuse/head";

export default {
  name: "PcBuilder",
  props: ["userInfo"],
  components: {
    ProductAddAlert, LoadingView
  },

  // meta tag setup start
  setup() {
    // Define reactive properties within the setup function
    const siteData = reactive({
      title: 'PC Builder - Build Your Own Computer | Build Your Own Customize Desktop Computer | Sopma Tech',
      description: "Sopma Tech is the Best Laptop, Computer, Gaming PC, Component, Accessories, and Gadget retail &amp; Online shop in Bangladesh. Sopma Tech offers the Latest Tech products with the most competitive price in BD.",
      keywords: "Laptop shop in Bangladesh, Laptop shop in bd, computer shop in Bangladesh, PC shop in Bangladesh, computer shop in BD, Gaming PC shop in Bangladesh, PC accessories shop in Bangladesh, best computer shop in Bangladesh, Gadget shop in bd, Gadget Shop in Bangladesh, Online Shop in BD, online computer shop in bd, computer accessories online shop in Bangladesh, computer parts shop in bd, Laptop in Bangladesh, Notebook, Laptop, Desktop, Brand PC, computer, computer store Bangladesh, laptop store Bangladesh, gaming, desktop, monitor, Sopma Tech, computer accessories, Desktop accessories, Laptop accessories, Laptop Online Store in BD, adata, apacer, apple, asus, bangladesh, baseus, belkin, benq, best, boya, brother, cable, camera, canon, GPU, graphics card, Sopma Tech &amp; Engineering Ltd, SOPMATECH, laptop price, laptop price in bangladesh, laptop price bd,",
    });

    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description),
        },
        {
          name: 'keywords',
          content: computed(() => siteData.keywords),
        },
      ],
    });
  },
  // meta tag setup end

  data() {
    return {
      showLoading: true,
      addAlert: "",
      alertMsg: "",
      coreComponents: [],
      peripheralsComponents: [],
      pcBuilderComponentCart: [],
      errorMsg: [],
    };
  },
  mounted() {
    this.getPcBuilderComponents();
    this.pcBuilderComponentCart = this.$store.getters.pcBuilderComponentCart;
    // let cart = JSON.parse(localStorage.getItem('pcBuilderComponentCart'));
    //     console.log(cart);
  },
  methods: {
    getPcBuilderComponents() {
      // on page loading
      this.showLoading = true;

      this.$store
        .dispatch("getPcBuilderComponents")
        .then((response) => {
          this.coreComponents = response.data.coreComponents;
          this.peripheralsComponents = response.data.peripherals;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          // off page loading
          this.showLoading = false;
        });
    },

    // remove pc builder product component form componet
    removePcComponent(componentId, index=false){
      let data = {
        componentId: componentId,
        index: index,
      }
      this.$store
        .dispatch("removePcBuilderComponent", data)
        .then((response) => {
          this.pcBuilderComponentCart = this.$store.getters.pcBuilderComponentCart
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // pc save function
    savePc(){
      let requiredMsg = [];
      this.errorMsg = [];
      this.coreComponents.forEach(item =>{        
        if(item.required){
          let componentId = 'componentId'+item.category_id;
          if(!this.pcBuilderComponentCart[componentId]){
            requiredMsg.push(item.category.category_name +' is required!');
          }
        }        
      });
      if(requiredMsg.length){
        this.errorMsg = requiredMsg;
      }else{
        this.$router.push('/save-pc');
      }
    },

    // add to cart
    // pc builder component add to cart
    addToCart(){
      // core components data insert
      this.coreComponents.forEach(item =>{
        let componentId = 'componentId'+item.category_id;
        if(this.pcBuilderComponentCart[componentId]){
          this.pcBuilderComponentCart[componentId].products.forEach(product => {
            
            let cartForm = {
              qty: 1,
              cartId: product.product.component_product_id,
              product: {
                id: product.product.id,
                product_id: product.product.component_product_id,
                barcode: product.product.barcode,
                category_id: product.product.category_id,
                brand_id: product.product.brand_id,
                product_name: product.product.product_name,
                product_model: product.product.product_model,
                description: product.product.description,
                specification: product.product.specification,
                slug: product.product.slug,
                product_unit: product.product.product_unit,
                variation_type: product.product.variation_type,
                product_type: product.product.product_type,
                original_price: product.product.original_price,
                sale_price: product.product.sale_price,
                regular_price: product.product.regular_price,
                discount: product.product.discount,
                warranty: product.product.warranty,
                word_warranty: product.product.word_warranty, 
                status: product.product.status,
                stock_status: product.product.stock_status,
                feature_pro_status: product.product.feature_pro_status,
                hot_product: product.product.hot_product,
                thumbnail: product.product.thumbnail,
                stock_qty: product.product.stock_qty,
                user_id: product.product.user_id,
                delete_user: product.product.delete_user,
                created_at: product.product.created_at,
                updated_at: product.product.updated_at,
                deleted_at: product.product.deleted_at,
                category_name: product.product.category_name,
                brand_name: product.product.brand_name,
              },
            };
            this.$store.dispatch("addToCart", cartForm);
          });
        }                         
      });

      // core components data insert
      this.peripheralsComponents.forEach(item =>{
        let componentId = 'componentId'+item.category_id;
        if(this.pcBuilderComponentCart[componentId]){
          this.pcBuilderComponentCart[componentId].products.forEach(product => {
            let cartForm = {
              qty: 1,
              cartId: product.product.component_product_id,
              product: {
                id: product.product.id,
                product_id: product.product.component_product_id,
                barcode: product.product.barcode,
                category_id: product.product.category_id,
                brand_id: product.product.brand_id,
                product_name: product.product.product_name,
                product_model: product.product.product_model,
                description: product.product.description,
                specification: product.product.specification,
                slug: product.product.slug,
                product_unit: product.product.product_unit,
                variation_type: product.product.variation_type,
                product_type: product.product.product_type,
                original_price: product.product.original_price,
                sale_price: product.product.sale_price,
                regular_price: product.product.regular_price,
                discount: product.product.discount,
                warranty: product.product.warranty,
                word_warranty: product.product.word_warranty, 
                status: product.product.status,
                stock_status: product.product.stock_status,
                feature_pro_status: product.product.feature_pro_status,
                hot_product: product.product.hot_product,
                thumbnail: product.product.thumbnail,
                stock_qty: product.product.stock_qty,
                user_id: product.product.user_id,
                delete_user: product.product.delete_user,
                created_at: product.product.created_at,
                updated_at: product.product.updated_at,
                deleted_at: product.product.deleted_at,
                category_name: product.product.category_name,
                brand_name: product.product.brand_name,
              },
            };
            cartForm.product = product.product;
            // this.$store.dispatch("addToCart", cartForm);
          })
        }                         
      });      
      
      if(this.userInfo){
        this.$router.push('/checkout');
      }else{
        this.alertMsg = 'PC Builder Componets';
        this.addAlert = "show d-block";
      }
    },

    // alert modal close function
    modalClose() {
      this.addAlert = "";
    },
  },
  watch: {},
};
</script>
<style scoped>
  .budgetPcInput{
    width: 250px;
  }
  .component-bg{
    background: #f2f4f8;
    color: black;
    font-weight: bold;
  }
  .component-img{
    width: 60px;
    border-right: 1px solid #f2f4f8;
  }
  .price{
    width: 100px;
    border-right: 1px solid #f2f4f8;
  }
  .action{
    width: 100px;
    text-align: center;
  }
  .pc-icon{
    font-size: 20px;
    line-height: 0;
  }
  .add-more{
    font-size: 10px;
  }
  table tbody tr td{
    border-bottom: 1px solid #f2f4f8;
  }
  .cr-p{
    cursor: pointer;
  }
</style>