<template>
  <div id="offer" class="col-md-12 px-3 mt-2 mb-5">
    <div class="row">
      <div class="col-md-12">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/" class="text-decoration-none"
                ><font-awesome icon="home"
              /></router-link>
            </li>
            <li class="breadcrumb-item">
              <a href="#" class="text-decoration-none text-capitalize"
                >Offers</a
              >
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="row" v-if="offers">
      <div
        class="col-xl-4 col-md-6 col-sm-6 col-12 offer"
        v-for="offer in offers"
        :key="offer.offer_id"
      >
        <div class="item">
          <div class="product p-2 mb-0 text-center">
            <img
              :src="offer.image"
              class="img-fluid"
              style="max-height: 170px"
            />
            <br />
            <small class="mb-0 py-2 d-block text-dark time text-center">
              <font-awesome icon="calendar" />
              {{ dateTime(offer.start_from) }} - {{ dateTime(offer.end_to) }}
            </small>
            <p class="border-bottom mb-0 pt-1"></p>
            <p
              class="mb-0 py-2 text-dark fw-bold text-center text-uppercase title"
            >
              {{ offer.title }}
            </p>
            <router-link
              :to="'/offer-info/' + offer.slug + '/' + offer.offer_id"
              class="btn btn-primary mt-3 border-0"
              type="button"
              style="font-size: 14px"
            >
              View Details
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-md-12 font-weight-bold text-center">Offer Not Found!</div>
    </div>

    <!-- loading view -->
    <LoadingView v-if="showLoading"/>
  </div>
</template>
<script>
import moment from "moment";
import LoadingView from '@/components/Layouts/LoadingView.vue';
import { reactive, computed } from 'vue';
import { useHead } from "@vueuse/head";

export default {
  name: "OfferView",
  props: ["imageBaseUrl"],
  components: { LoadingView },

  // meta tag setup start
  setup() {
    const siteData = reactive({
      title: 'Latest Sopma Tech Offers | Latest Offers | Sopma Tech',
      description: "Sopma Tech is the Best Laptop, Computer, Gaming PC, Component, Accessories, and Gadget retail &amp; Online shop in Bangladesh. Sopma Tech offers the Latest Tech products with the most competitive price in BD.",
      keywords: "Laptop shop in Bangladesh, Laptop shop in bd, computer shop in Bangladesh, PC shop in Bangladesh, computer shop in BD, Gaming PC shop in Bangladesh, PC accessories shop in Bangladesh, best computer shop in Bangladesh, Gadget shop in bd, Gadget Shop in Bangladesh, Online Shop in BD, online computer shop in bd, computer accessories online shop in Bangladesh, computer parts shop in bd, Laptop in Bangladesh, Notebook, Laptop, Desktop, Brand PC, computer, computer store Bangladesh, laptop store Bangladesh, gaming, desktop, monitor, Sopma Tech, computer accessories, Desktop accessories, Laptop accessories, Laptop Online Store in BD, adata, apacer, apple, asus, bangladesh, baseus, belkin, benq, best, boya, brother, cable, camera, canon, GPU, graphics card, Sopma Tech &amp; Engineering Ltd, SOPMATECH, laptop price, laptop price in bangladesh, laptop price bd,",
    });

    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description),
        },
        {
          name: 'keywords',
          content: computed(() => siteData.keywords),
        },
      ],
    });
  },
  // meta tag setup end


  data() {
    return {
      offers: "",
      showLoading: true,
    };
  },
  mounted() {
    this.getOffers();
  },
  methods: {
    getOffers() {
      // on page loading
      this.showLoading = true;
      this.$store
        .dispatch("GetOffer")
        .then((response) => {
          this.offers = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          // off page loading
          this.showLoading = false;
      });
    },
    dateTime(value) {
      return moment(value).format("DD MMM YYYY hh:mma");
    },
  },
  watch: {},
};
</script>
<style scoped>
.item {
  background: white;
  padding: 20px;
  border-radius: 5px;
}
.btn-primary {
  background: #f2f4f8;
  color: #932a8f;
  font-weight: bold;
  transition: 1s;
}
.btn-out {
  background: #d4d5d7;
  color: #932aaa;
  font-weight: bold;
}
.btn-primary:hover {
  background-color: #932a8f;
  box-shadow: 0px 3px 3px 1px #f2f4f8;
  color: #fffcfccc;
  transition: 1s;
}
</style>