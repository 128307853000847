<template>
    <div id="footer-area" class="col-md-12 py-3">
        <div class="row py-5 text-secondary">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 about">
                <h6 class="text-white ps-3">Useful Links</h6>
                <div class="row px-0 mx-0">
                    <div class="col">
                        <ul class="nav flex-column">
                            <li class="nav-item">
                                    <router-link class="text-secondary nav-link ps-0" to="/terms-conditions">Terms and Conditions</router-link>
                                </li>
                            <li class="nav-item">
                                    <router-link class="text-secondary nav-link ps-0" to="/refund-return-policy">Refund and Return Policy</router-link>
                                </li>
                            <li class="nav-item">
                                    <router-link class="text-secondary nav-link ps-0" to="/online-delivery">Online Delivery</router-link>
                                </li>
                        </ul>
                    </div>
                    <div class="col">
                        <ul class="nav flex-column">
                            <li class="nav-item">
                                    <router-link class="text-secondary nav-link ps-0" to="/privacy-policy">Privacy Policy</router-link>
                                </li>
                            <li class="nav-item">
                                    <router-link class="text-secondary nav-link ps-0" to="/about-us">About Us</router-link>
                                </li>
                            <li class="nav-item">
                                    <router-link class="text-secondary nav-link ps-0" to="/contact">Contact</router-link>
                                </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-sm-center contact">
                <h6 class="text-white">STAY CONNECT WITH US</h6>
                <h6 class="text-uppercase">{{ setting.name }}</h6>
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <p class="nav-link text-secondary mb-0"><font-awesome icon="location-dot" /> {{ setting.address }}</p>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-secondary" :href="'mailto:'+setting.email"><font-awesome icon="envelope" /> {{ setting.email }}</a>
                    </li>
                    <li class="nav-item">
                        <p v-for="phone in phones" :key="phone" class="m-0 nav-link text-secondary"><font-awesome icon="phone" /> {{ phone }}</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row footer border-top border-secondary">
            <div class="col-md-12 text-center pt-3">
                <p class="py-2 text-secondary">&copy; 2020 All Rights Reserved by {{ setting.name }}</p>
            </div>
            <!-- <div class="col-md-6"> -->
                <!-- <ul class="footer_payment text-center text-lg-right">
                    <li><a href="#"><img src="assets/images/visa.png" alt="visa"></a></li>
                    <li><a href="#"><img src="assets/images/discover.png" alt="discover"></a></li>
                    <li><a href="#"><img src="assets/images/master_card.png" alt="master_card"></a></li>
                    <li><a href="#"><img src="assets/images/paypal.png" alt="paypal"></a></li>
                    <li><a href="#"><img src="assets/images/amarican_express.png" alt="amarican_express"></a></li>
                </ul> -->
            <!-- </div> -->
        </div>
    </div>
</template>
<script>
    export default{
        name: 'FooterView',
        data(){
            return{
                setting: '',
                phones: [],
            }
        },

        methods: {
            // setting data fetch function
            getSettingData(){
                let setting = this.$store.getters.siteSetting;
                if(setting){
                    this.setting = setting;
                    this.phones = JSON.parse(setting.phone);
                }else{
                    console.log('server');
                    this.$store.dispatch("getSettingData").then((response) => {
                        this.setting = response.data;
                        this.phones = JSON.parse(response.data.phone);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                }
            },

            
        },

        // 
        mounted() {
            // call getSettingData for get site setting data
            this.getSettingData();
        },
        
    }
</script>

<style scoped>
    #footer-area{
        background: #081621; color: white;
    }
    .logoimg{
        height: 60px;
    }
    .footer{
        background: #081621;
    }

    @media screen and (min-width: 480px) and (max-width: 767px) {
        .account, .contact{
            margin-top: 30px; text-align: center;
        }
        .about{
            text-align: center;
        }
    }
</style>