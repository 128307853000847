<template>
    <div class="register col-md-12 px-3 mt-2 mb-5 mx-auto" @keyup.enter="update">
      <div class="row">
        <div class="col-md-12">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/" class="text-decoration-none"
                  ><font-awesome icon="home"
                /></router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link
                  to="/pc-builder"
                  class="text-decoration-none text-capitalize">PC Builder</router-link>
              </li>
              <li class="breadcrumb-item">
                <a href="#" class="text-decoration-none text-capitalize">Save PC</a>
              </li>
            </ol>
          </nav>
        </div>
        <div class="col-md-12">
            <form action="" class="mx-5 bg-white border p-3 rounded">
                <div class="form-group mb-3">
                    <label for="pcName">PC Name <span class="text-danger">*</span></label>
                    <input type="text" v-model="name" class="form-control" id="pcName" maxlength="255">
                    <span class="text-danger">{{  nameError  }}</span>
                </div>
                <div class="form-group mb-3">
                    <label for="description">Description <span class="text-danger">*</span></label>
                    <textarea v-model="description" id="description" rows="3" class="form-control"></textarea>
                </div>

                <div class="form-group text-end mb-3">
                    <button type="reset" class="btn btn-primary me-2">Reset</button>
                    <button type="button" class="btn btn-primary" @click="savePc">Save</button>
                </div>
            </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "AccountView",
    props: ["userInfo", "imageBaseUrl"],
    data() {
      return {
        name: '',
        description: '',
        nameError: '',
        coreComponents: [],
        peripheralsComponents: [],
        pcBuilderComponentCart: {},                
      };
    },
    methods: {
        getPcBuilderComponents() {
            this.$store
                .dispatch("getPcBuilderComponents")
                .then((response) => {
                this.coreComponents = response.data.coreComponents;
                this.peripheralsComponents = response.data.peripherals;
                })
                .catch((error) => {
                console.log(error);
                });
            },
        savePc(){
            let success = true;
            this.nameError = '';
            if(this.name.length == 0){
                this.nameError = 'PC name is required!';
                success = false;
            }
            if(success){
                let formData = {
                    name: this.name,
                    description: this.description,
                    components: [],
                };
                // core components data insert
                this.coreComponents.forEach(item =>{
                    let componentId = 'componentId'+item.category_id;
                    if(this.pcBuilderComponentCart[componentId]){
                        this.pcBuilderComponentCart[componentId].products.forEach(product => {
                            formData.components.push({
                                pc_builder_component_id: item.id,
                                product_id: product.product.product_id,
                                variation_id: '',
                                qty: 1,
                            });
                        });
                    }                         
                });
                // core components data insert
                this.peripheralsComponents.forEach(item =>{
                    let componentId = 'componentId'+item.category_id;
                    if(this.pcBuilderComponentCart[componentId]){
                        this.pcBuilderComponentCart[componentId].products.forEach(product => {
                            formData.components.push({
                                pc_builder_component_id: item.id,
                                product_id: product.product.product_id,
                                variation_id: '',
                                qty: 1,
                            })
                        })
                    }                         
                });
                this.$store.dispatch("savePcBuilder", formData)
                    .then((response) => {
                        console.log(response);
                        this.$router.push('saved-pc-list');
                    })
                    .catch((error) => {
                    console.log(error);
                });
            }
        }
    },
    mounted() {
        this.getPcBuilderComponents();
        this.pcBuilderComponentCart = this.$store.getters.pcBuilderComponentCart;
    },
  };
  </script>
  
