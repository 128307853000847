<template>
    <div class="product-area col-md-12 px-3 mt-2 mb-5">
        <div class="row">
            <div class="col-md-12">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                        <router-link to="/" class="text-decoration-none"
                            ><font-awesome icon="home"
                        /></router-link>
                        </li>
                        <li class="breadcrumb-item">
                        <a href="#" class="text-decoration-none text-capitalize">Contact</a>
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
        <div class="row bg-white sort-area rounded mx-1 py-2 mb-3">
            <div class="col-md-12 p-4">
                <p><font-awesome icon="location-dot" /> Address: {{ setting.address }}</p>
                <p><font-awesome icon="envelope" /> E-mail: {{ setting.email }}</p>
                <div class="d-flex">
                    <div>
                        <font-awesome icon="phone" /> Phone:
                    </div>
                    <div class="ps-1">
                        <p v-for="phone in phones" :key="phone" class="m-0">{{ phone }}</p>
                    </div>
                 </div>
            </div>
        </div>
    </div>
</template>
<script>

export default{
    name: 'ContactView',
    data(){
        return{
            setting: '',
            phones: [],
        }
    },

    methods: {
        // setting data fetch function
        getSettingData(){
            let setting = this.$store.getters.siteSetting;
            if(setting){
                this.setting = setting;
                this.phones = JSON.parse(setting.phone);
            }else{
                console.log('server');
                this.$store.dispatch("getSettingData").then((response) => {
                    this.setting = response.data;
                    this.phones = JSON.parse(response.data.phone);
                })
                .catch((error) => {
                    console.log(error);
                });
            }
        }
    },

      // 
    mounted() {
        // call getSettingData for get site setting data
        this.getSettingData();
    },
    
}
</script>