<template>
  <div class="product-area col-md-12 px-3 mt-2 mb-5">
    <div class="row">
      <div class="col-md-12">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/" class="text-decoration-none"
                ><font-awesome icon="home"
              /></router-link>
            </li>
            <li class="breadcrumb-item">
              <a href="#" class="text-decoration-none text-capitalize">{{
                brandName
              }}</a>
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-xl-2 filter-area px-0">
        <div
          class="price-range d-block float-start w-100 rounded bg-white px-2 py-3"
        >
          <p class="fw-bold filter-border mb-3 pb-2">
            <small>Price Range</small>
          </p>
          <div class="range d-block px-1">
            <div class="track-container">
              <div class="track" ref="_vpcTrack"></div>
              <div class="track-highlight" ref="trackHighlight"></div>
              <button class="track-btn track1" ref="track1"></button>
              <button class="track-btn track2" ref="track2"></button>
            </div>
          </div>
          <div class="d-block float-start mt-3">
            <input
              type="text"
              class="text-center min"
              v-model="minValue"
              @blur="priceFilter($event)"
            />
            <input
              type="text"
              class="text-center max"
              v-model="maxValue"
              @blur="priceFilter($event)"
            />
          </div>
        </div>
        <div
          class="availability-area d-block w-100 mt-1 px-2 py-3 bg-white rounded float-start"
        >
          <p class="fw-bold filter-border mb-0 pb-2">
            <small>Availability</small>
            <span class="float-end px-2" @click="statusToggle()"
              ><font-awesome
                :icon="this.statusShow ? 'angle-up' : 'angle-down'"
            /></span>
          </p>
          <div v-if="this.statusShow">
            <div class="form-group px-2 py-1 availability-grp">
              <input
                type="checkbox"
                id="instock"
                value="1"
                @click="stockStatusf($event)"
                class="me-2 availability availability-check"
              />
              <label for="instock">In Stock</label>
            </div>
            <div class="form-group px-2 py-1 availability-grp">
              <input
                type="checkbox"
                id="upcomming"
                value="3"
                @click="stockStatusf($event)"
                class="me-2 availability availability-check"
              />
              <label for="upcomming">Up Comming</label>
            </div>
          </div>
        </div>
        <div
          v-for="(cat, si) in attributes"
          :key="si"
          class="variationtype-area d-block w-100 mt-1 px-2 py-3 bg-white rounded float-start"
        >
          <p class="fw-bold filter-border mb-0 pb-2 text-capitalize">
            <small>{{ cat.category }}</small>
            <span class="float-end px-2" @click="attributeToggle(si)"
              ><font-awesome :icon="cat.show ? 'angle-up' : 'angle-down'"
            /></span>
          </p>
          <div v-if="cat.show">
            <div
              v-for="variation in cat.attributes"
              :key="variation.variation_id"
              class="form-group px-2 py-1 availability-grp"
            >
              <input
                type="checkbox"
                name="variation_id[]"
                :id="'variationId' + variation.variation_id"
                :value="variation.variation_id"
                @click="attributeClick($event)"
                class="me-2 availability availability-check"
              />
              <label :for="'variationId' + variation.variation_id">{{
                variation.variation_name
              }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-10 col-md-12 col-sm-12 col-12">
        <div class="row bg-white sort-area rounded mx-1 py-2 mb-3">
          <div class="col-md-12">
            <div class="filter-btn float-start px-2 py-1" @click="filterClose">
              <font-awesome icon="bars" class="me-1 d-inline-block" />
              <span class="d-inline-block">Filter</span>
            </div>
            <div class="sort-area float-end">
              <div class="per-page d-inline-block me-2 px-2 py-1">
                <label for="">Show: &nbsp;</label>
                <select v-model="requestData.limit" class="limit">
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="45">45</option>
                  <option value="70">70</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div class="filter-sort d-inline-block px-2 py-1">
                <label for="">Sort By: &nbsp;</label>
                <select v-model="requestData.order" class="sort">
                  <option value="">Default</option>
                  <option value="asc">Price (Low to High)</option>
                  <option value="desc">Price (High to Low)</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="pro-card-area mt-1" v-if="products.data">
          <div
            class="pro-card"
            v-for="product in products.data"
            :key="product.product_id"
          >
            <div class="pro-card-inner">
              <div class="marks" v-if="product.discount > 0">
                <span class="mark"
                  >Save:
                  {{
                    (
                      product.original_price - product.sale_price
                    ).toLocaleString()
                  }}৳</span
                >
              </div>
              <div class="pro-image">
                <router-link
                  :to="{
                    name: 'productDetails',
                    params: {
                      productSlug: product.slug,
                    },
                  }"
                >
                  <img
                    v-if="product.thumbnail"
                    :src="product.thumbnail"
                    :alt="product.product_name"
                    class="thumble"
                  />
                  <img
                    v-else
                    src="@/assets/product-default.jpeg"
                    :alt="product.product_name"
                    class="thumble"
                  />
                </router-link>
              </div>
              <div class="pro-details">
                <h4 class="pro-name">
                  <router-link
                    :to="{
                      name: 'productDetails',
                      params: {
                        productSlug: product.slug,
                        brandSlug: brandSlug,
                      },
                    }"
                    class="nav-link p-0 text-start"
                    >{{ product.product_name }}</router-link
                  >
                </h4>
              </div>
              <div class="justify-content-center buy-btn">
                <div class="pro-price py-2 text-center pb-3">
                  {{ product.sale_price.toLocaleString() }}৳
                  <small class="ml-3" v-if="product.discount > 0"
                    ><del class="text-secondary"
                      >{{
                        (
                          product.original_price - product.sale_price
                        ).toLocaleString()
                      }}৳</del
                    ></small
                  >
                </div>
                <button
                  class="btn btn-out mb-2 btn-sm float-start py-2 col-12"
                  v-if="product.stock_status == 2"
                >
                  Stock Out
                </button>
                <button
                  class="btn btn-out mb-2 btn-sm float-start py-2 col-12"
                  v-else-if="product.stock_status == 3"
                >
                  Up Coming
                </button>
                <div v-else>
                  <router-link
                    v-if="product.variation_type"
                    class="btn btn-primary mb-2 btn-sm float-start col-12 py-2 border-0"
                    :to="{
                      name: 'productDetails',
                      params: {
                        productSlug: product.slug,
                        brandSlug: brandSlug,
                      },
                    }"
                  >
                    <font-awesome icon="cart-arrow-down" /> Buy Now
                  </router-link>
                  <button
                    v-else
                    class="btn btn-primary mb-2 btn-sm float-start col-12 py-2 border-0"
                    @click="addToCart(product)"
                  >
                    <font-awesome icon="cart-arrow-down" /> Buy Now
                    {{ product.variation_type }}
                  </button>
                </div>
                <button
                  class="btn btn-secondary mb-1 btn-sm float-end col-12 py-2"
                  @click="addtoComparef(product)"
                >
                  <font-awesome icon="shuffle" class="" /> Add to Compare
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="col-md-12 py-5 my-5">
          <h4 class="text-center py-5">Product Not Found!!!</h4>
        </div>
        <div class="row mt-4 px-3" v-if="products.total">
          <div class="col-md-12 bg-white rounded p-2 shadow">
            <div v-if="products.last_page > 1" class="d-inline-block">
              <div class="d-inline-block" v-if="products.current_page == 1">
                <button class="btn btn-sm btn-success me-2 mt-1 page-btn">
                  PREV
                </button>
              </div>
              <div class="d-inline-block" v-else>
                <button
                  class="btn btn-sm btn-success me-2 mt-1 page-btn"
                  @click="paginationPro(products.current_page - 1)"
                >
                  PREV
                </button>
              </div>
              <div
                class="d-inline-block"
                v-for="page in products.last_page"
                :key="page"
              >
                <button
                  v-if="
                    page > products.current_page - 4 &&
                    page < products.current_page + 4
                  "
                  class="btn btn-sm link-btn me-2 mt-1"
                  :class="page == products.current_page ? 'link-active' : ''"
                  @click="paginationPro(page)"
                >
                  {{ page }}
                </button>
              </div>
              <div
                class="d-inline-block"
                v-if="products.current_page == products.last_page"
              >
                <button class="btn btn-sm btn-success mt-1 page-btn">
                  NEXT
                </button>
              </div>
              <div class="d-inline-block" v-else>
                <button
                  class="btn btn-sm btn-success mt-1 page-btn"
                  @click="paginationPro(products.current_page + 1)"
                >
                  NEXT
                </button>
              </div>
            </div>
            <div class="page-number d-inline-block float-end">
              <p class="mt-1 mb-0">
                <small class="fw-bold page-btn"
                  >Showing {{ products.from }} to {{ products.to }} of
                  {{ products.total }} ({{ products.last_page }}
                  {{ products.last_page > 1 ? "Pages" : "Page" }})</small
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- responsive filter area -->
    <div class="resp-filter-area" :class="filterModal ? '' : 'd-none'">
      <div class="colose-area text-end mt-2 bg-white p-1 rounded">
        <font-awesome
          icon="close"
          class="btn btn-primary"
          @click="filterClose"
        />
      </div>
      <div class="responsive-filter mt-3">
        <div
          class="price-range d-block float-start w-100 rounded bg-white px-2 py-3"
        >
          <p class="fw-bold filter-border mb-0 pb-2">
            <small>Price Range</small>
          </p>
          <div class="d-block float-start mt-3">
            <div class="left-min min">
              <input
                type="text"
                class="text-center min-resp"
                v-model="requestData.price[0]"
                @blur="priceFilter($event)"
              />
              <br />
              <span>Min</span>
            </div>
            <div class="right-max max">
              <input
                type="text"
                class="text-center max-resp"
                v-model="requestData.price[1]"
                @blur="priceFilter($event)"
              />
              <br />
              <span class="float-end">Max</span>
            </div>
          </div>
        </div>
        <div
          class="availability-area d-block w-100 mt-1 px-2 py-3 bg-white rounded float-start"
        >
          <p class="fw-bold filter-border mb-0 pb-2">
            <small>Availability</small>
            <span class="float-end px-2" @click="statusToggle()"
              ><font-awesome
                :icon="this.statusShow ? 'angle-up' : 'angle-down'"
            /></span>
          </p>
          <div v-if="this.statusShow">
            <div class="form-group px-2 py-1 availability-grp">
              <input
                type="checkbox"
                id="rinstock"
                value="1"
                @click="stockStatusf($event)"
                class="me-2 availability"
              />
              <label for="rinstock">In Stock</label>
            </div>
            <div class="form-group px-2 py-1 availability-grp">
              <input
                type="checkbox"
                id="rupcomming"
                value="3"
                @click="stockStatusf($event)"
                class="me-2 availability availability-check"
              />
              <label for="rupcomming">Up Comming</label>
            </div>
          </div>
        </div>
        <div
          v-for="(cat, si) in attributes"
          :key="si"
          class="variationtype-area d-block w-100 mt-1 px-2 py-3 bg-white rounded float-start"
        >
          <p class="fw-bold filter-border mb-0 pb-2">
            <small>{{ cat.catehory_name }}</small>
            <span class="float-end px-2" @click="attributeToggle(si)"
              ><font-awesome :icon="cat.show ? 'angle-up' : 'angle-down'"
            /></span>
          </p>
          <div v-if="cat.show">
            <div
              v-for="variation in cat.attributes"
              :key="variation.variation_id"
              class="form-group px-2 py-1 availability-grp"
            >
              <input
                type="checkbox"
                :id="'rvariationId' + variation.variation_id"
                :value="variation.variation_id"
                class="me-2 availability availability-check"
                @click="attributeClick($event)"
              />
              <label :for="'rvariationId' + variation.variation_id">{{
                variation.variation_name
              }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- alert modal  -->
    <ProductAddAlert
      :modalAlert="addAlert"
      :alertMsg="alertMsg"
      @modalClose="modalClose"
    />

    <!-- loading view -->
    <LoadingView v-if="showLoading"/>

  </div>
</template>
<script>
import ProductAddAlert from "@/components/ProductAddAlert.vue";
import LoadingView from '@/components/Layouts/LoadingView.vue';
import { reactive, watch, computed, ref } from 'vue';
import { useHead } from "@vueuse/head";

export default {
  name: "CategoryProductView",
  props: ["filterModal"],
  components: {
    ProductAddAlert, LoadingView
  },

  // meta tag setup start
  setup() {
    // Define reactive properties within the setup function
    const siteTitle = ref('siteTitle');
    const siteData = reactive({
      title: siteTitle.value +` - Buy ${ siteTitle.value } Authorized All Products at Best Price in Bangladesh | Sopma Tech`,
      description: "Sopma Tech is the Best Laptop, Computer, Gaming PC, Component, Accessories, and Gadget retail &amp; Online shop in Bangladesh. Sopma Tech offers the Latest Tech products with the most competitive price in BD.",
      keywords: "Laptop shop in Bangladesh, Laptop shop in bd, computer shop in Bangladesh, PC shop in Bangladesh, computer shop in BD, Gaming PC shop in Bangladesh, PC accessories shop in Bangladesh, best computer shop in Bangladesh, Gadget shop in bd, Gadget Shop in Bangladesh, Online Shop in BD, online computer shop in bd, computer accessories online shop in Bangladesh, computer parts shop in bd, Laptop in Bangladesh, Notebook, Laptop, Desktop, Brand PC, computer, computer store Bangladesh, laptop store Bangladesh, gaming, desktop, monitor, Sopma Tech, computer accessories, Desktop accessories, Laptop accessories, Laptop Online Store in BD, adata, apacer, apple, asus, bangladesh, baseus, belkin, benq, best, boya, brother, cable, camera, canon, GPU, graphics card, Sopma Tech &amp; Engineering Ltd, SOPMATECH, laptop price, laptop price in bangladesh, laptop price bd,",
    });

    // Watch for changes in siteTitle and update siteData.title accordingly
    watch(siteTitle, (newSiteTitle) => {
      siteData.title = newSiteTitle +` - Buy ${ newSiteTitle } Authorized All Products at Best Price in Bangladesh | Sopma Tech`
    });

    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description),
        },
        {
          name: 'keywords',
          content: computed(() => siteData.keywords),
        },
      ],
    });

    // Access siteTitle directly in setup
    return {
      siteTitle,
    };
  },
  // meta tag setup end

  data() {
    return {
      showLoading: true,
      addAlert: "",
      alertMsg: "",
      loading: false,
      brandName: "",
      brandSlug: "",
      products: [],
      attributeShow: [],
      statusShow: true,
      attributes: [],
      min: 0,
      max: 1000000,
      minValue: 0,
      maxValue: 1000000,
      step: 5,
      totalSteps: 0,
      percentPerStep: 1,
      trackWidth: null,
      isDragging: false,
      pos: {
        curTrack: null,
      },
      requestData: {
        price: [0, 1000000],
        limit: 20,
        order: "",
        stock_status: [],
        variation_id: [],
        slug: "",
        page: 1,
      },
    };
  },
  methods: {
    // product add to compare function
    addtoComparef(product) {
      this.$store.dispatch("addtoCompare", product);
    },

    // product add to cart function
    addToCart(product) {
      let cartForm = {
        qty: 1,
        product: product,
        cartId: product.product_id,
      };
      this.$store.dispatch("addToCart", cartForm);
      this.alertMsg = product.product_name;
      this.addAlert = "show d-block";
    },

    paginationPro(page) {
      this.requestData.page = page;
      this.$store
        .dispatch("brandProducts", this.requestData)
        .then((response) => {
          this.products = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.products = [];
        });
    },

    // get category product function
    getProducts() {
      // on page loading
      this.showLoading = true;

      this.requestData.page = 1;
      this.$store
        .dispatch("brandProducts", this.requestData)
        .then((response) => {
          this.products = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.products = [];
        }).finally(() => {
          // off page loading
          this.showLoading = false;
        });
    },

    // get category products attributes and brands
    getBrandsAttributes() {
      //   this.$store
      //     .dispatch("brandAttributes", this.$route.params.brandSlug)
      //     .then((response) => {
      //       this.attributes = response.data.data.attributes;
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //       this.attributes = [];
      //     });
    },

    modalClose() {
      this.addAlert = "";
    },
    // responsive design filter close function
    filterClose() {
      this.$emit("filter");
    },

    moveTrack(track, ev) {
      let percentInPx = this.getPercentInPx();

      let trackX = Math.round(
        this.$refs._vpcTrack.getBoundingClientRect().left
      );
      let clientX = ev.clientX;
      let moveDiff = clientX - trackX;

      let moveInPct = moveDiff / percentInPx;
      // console.log(moveInPct)

      if (moveInPct < 1 || moveInPct > 100) return;
      let value =
        Math.round(moveInPct / this.percentPerStep) * this.step + this.min;
      if (track === "track1") {
        if (value >= this.maxValue - this.step) return;
        this.minValue = value;
        this.requestData.price = [value, this.maxValue];
      }

      if (track === "track2") {
        if (value <= this.minValue + this.step) return;
        this.maxValue = value;
        this.requestData.price = [this.minValue, value];
      }

      this.$refs[track].style.left = moveInPct + "%";
      this.setTrackHightlight();
    },
    mousedown(ev, track) {
      if (this.isDragging) return;
      this.isDragging = true;
      this.pos.curTrack = track;
      console.log(ev);
    },

    touchstart(ev, track) {
      this.mousedown(ev, track);
    },

    mouseup(ev, track) {
      if (!this.isDragging) return;
      this.isDragging = false;
      console.log(ev);
      console.log(track);
      this.getProducts();
    },

    touchend(ev, track) {
      // console.log('touch end');
      this.mouseup(ev, track);
    },

    mousemove(ev, track) {
      if (!this.isDragging) return;
      this.moveTrack(track, ev);
    },

    touchmove(ev, track) {
      this.mousemove(ev.changedTouches[0], track);
    },

    valueToPercent(value) {
      return ((value - this.min) / this.step) * this.percentPerStep;
    },

    setTrackHightlight() {
      this.$refs.trackHighlight.style.left =
        this.valueToPercent(this.minValue) + "%";
      this.$refs.trackHighlight.style.width =
        this.valueToPercent(this.maxValue) -
        this.valueToPercent(this.minValue) +
        "%";
    },

    getPercentInPx() {
      let trackWidth = this.$refs._vpcTrack.offsetWidth;
      let oneStepInPx = trackWidth / this.totalSteps;
      // 1 percent in px
      let percentInPx = oneStepInPx / this.percentPerStep;

      return percentInPx;
    },

    setClickMove(ev) {
      let track1Left = this.$refs.track1.getBoundingClientRect().left;
      let track2Left = this.$refs.track2.getBoundingClientRect().left;
      // console.log('track1Left', track1Left)
      if (ev.clientX < track1Left) {
        this.moveTrack("track1", ev);
      } else if (ev.clientX - track1Left < track2Left - ev.clientX) {
        this.moveTrack("track1", ev);
      } else {
        this.moveTrack("track2", ev);
      }
      this.getProducts();
    },

    // filter product attribute selection function
    attributeClick(event) {
      if (event.target.checked) {
        this.requestData.variation_id.push(event.target.value);
      } else {
        this.requestData.variation_id = this.requestData.variation_id.filter(
          (variation) => {
            return variation !== event.target.value;
          }
        );
      }
      this.getProducts();
    },

    // filter product stock status selection function
    stockStatusf(event) {
      if (event.target.checked) {
        this.requestData.stock_status.push(event.target.value);
      } else {
        this.requestData.stock_status = this.requestData.stock_status.filter(
          (statuss) => {
            return statuss !== event.target.value;
          }
        );
      }
      this.getProducts();
    },

    // price filter function
    priceFilter(event) {
      console.log(event.target.value);
      this.getProducts();
    },

    // product attribute show close function
    attributeToggle(si) {
      this.attributes[si].show = !this.attributes[si].show;
    },

    // product stock status show close function
    statusToggle() {
      this.statusShow = !this.statusShow;
    },

    // set default data
    dataSet() {
      this.requestData.price = [0, 1000000];
      this.requestData.limit = 20;
      this.requestData.order = "";
      this.requestData.stock_status = [];
      this.requestData.brand_id = [];
      this.requestData.variation_id = [];
      this.requestData.slug = this.$route.params.brandSlug;
      this.requestData.page = 1;

      this.brandSlug = this.$route.params.brandSlug;
    },

    setPriceRange(){
      // this.getFilters();
      // calc per step value
      this.totalSteps = (this.max - this.min) / this.step;

      // percent the track button to be moved on each step
      this.percentPerStep = 100 / this.totalSteps;
      // console.log('percentPerStep', this.percentPerStep)

      // set track1 initilal
      document.querySelector(".track1").style.left =
        this.valueToPercent(this.minValue) + "%";
      // track2 initial position
      document.querySelector(".track2").style.left =
        this.valueToPercent(this.maxValue) + "%";
      // set initila track highlight
      this.setTrackHightlight();

      var self = this;

      ["mouseup", "mousemove"].forEach((type) => {
        document.body.addEventListener(type, (ev) => {
          // ev.preventDefault();
          if (self.isDragging && self.pos.curTrack) {
            self[type](ev, self.pos.curTrack);
          }
        });
      });

      [
        "mousedown",
        "mouseup",
        "mousemove",
        "touchstart",
        "touchmove",
        "touchend",
      ].forEach((type) => {
        document.querySelector(".track1").addEventListener(type, (ev) => {
          ev.stopPropagation();
          self[type](ev, "track1");
        });
        document.querySelector(".track2").addEventListener(type, (ev) => {
          ev.stopPropagation();
          self[type](ev, "track2");
        });
      });

      // on track clik
      // determine direction based on click proximity
      // determine percent to move based on track.clientX - click.clientX
      document.querySelector(".track").addEventListener("click", function (ev) {
        ev.stopPropagation();
        self.setClickMove(ev);
      });

      document
        .querySelector(".track-highlight")
        .addEventListener("click", function (ev) {
          ev.stopPropagation();
          self.setClickMove(ev);
        });
    }
  },
  created() {
    // console.log("created");
    this.brandName = this.$route.params.brandSlug.replace(/-/g, " ");
    this.siteTitle = this.$ucWords(this.brandName);
  },
  mounted() {
    // document.onreadystatechange = () => {
    //     if (document.readyState == "complete") {
    //         this.loading = true;
    //         console.log('sdfsd');
    //     }
    // };
    // call product functon
    this.dataSet();
    this.getProducts();

    this.getBrandsAttributes();

    setTimeout(()=>{
      this.setPriceRange();
    },3000);
  },
  watch: {
    $route() {
      if (this.$route.name == "BrandProducts") {
        this.dataSet();
        this.getProducts();
        let allSelected = this.$el.querySelectorAll(".availability-check");
        allSelected.forEach((singleSL) => {
          singleSL.checked = false;
        });
        this.$el.querySelector(".limit").value = 20;
        this.$el.querySelector(".sort").value = "";
      }
    },
    // // if change order then will be call getproducts function
    ["requestData.order"]() {
      this.getProducts();
    },
    // if change limit then will be call getproducts function
    ["requestData.limit"]() {
      this.getProducts();
    },
  },
};
</script>
<style scoped>
.feature {
  border-bottom: 2px solid #942a8f !important;
}
.thumble {
  height: 221px;
  width: 221px;
  text-align: center;
}
.pro-card-area {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  padding: 0;
  justify-content: flex-start;
  background: none;
}

.pro-card {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 5px 10px;
  margin-bottom: 0;
  display: flex;
  position: relative;
}
.pro-card-inner {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
}
.pro-card-inner:hover {
  box-shadow: 0px 0px 8px 1px gray;
}
.marks {
  display: flex;
  position: absolute;
  top: 5px;
  left: 0;
  z-index: 10;
  flex-direction: column;
  align-items: flex-start;
}
.mark {
  background: #ed283a;
  width: auto;
  color: #fff;
  font-size: 12px;
  padding: 3px 10px;
  line-height: 14px;
  margin-bottom: 2px;
  border-radius: 0 20px 20px 0;
  flex: 0 0 auto;
}

.pro-image {
  text-align: center;
  border-bottom: 3px solid rgba(55, 73, 187, 0.03);
  flex: 0 0 220px;
  padding: 20px;
  margin: 0;
}
.pro-image img {
  max-width: 100%;
}
.pro-details {
  padding: 10px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  border-bottom: 3px solid rgba(55, 73, 187, 0.03);
}
.price-card {
  font-size: 14px;
}
.pro-name {
  font-weight: 400;
  font-size: 15px;
}
.pro-price {
  line-height: 22px;
  font-size: 17px;
  font-weight: 600;
  color: #ef4a23;
}
.text-dark {
  color: black;
}
.buy-btn {
  padding: 10px;
}
.btn-primary {
  background: #f2f4f8;
  color: #932a8f;
  font-weight: bold;
  transition: 1s;
}
.btn-out {
  background: #d4d5d7;
  color: #932aaa;
  font-weight: bold;
}
.btn-primary:hover {
  background-color: #932a8f;
  box-shadow: 0px 3px 3px 1px #f2f4f8;
  color: #fffcfccc;
  transition: 1s;
}
.btn-secondary{
  background-color: #f2f4f86b;
  transition: 1s;
  font-weight: 500;
  border: 0;
  color: rgb(0, 0, 0);
}

.btn-secondary:hover {
  box-shadow: 0px 3px 3px 1px #f2f4f8;
  color: #932a8f;
  background-color: #cacdd5;
}
.gallary-img {
  height: 50px;
  width: 50px;
  border: 1px solid lightgray;
  padding: 5px;
  margin: 1px;
}
.price-bg {
  background: #f1f1f3d1;
}
.link-btn {
  background: #1934cfad;
  color: white;
}
.filter-border {
  border-bottom: 1px solid #80808057;
}
.link-active {
  background: #00008bc7;
  box-shadow: 0px 0px 4px 1px #1934cf;
}
.filter-btn,
.per-page,
.filter-sort {
  background: #e7e9ed;
  color: black;
  font-size: 14px;
  cursor: pointer;
}
.availability {
  height: 16px;
  width: 16px;
  position: relative;
  top: 3px;
}
.availability-grp:hover {
  background: #02189178;
  color: white;
}
.resp-filter-area {
  position: absolute;
  width: 280px;
  background: #f2f4f8;
  top: 0;
  left: 10px;
  box-shadow: 0 0 10px 5px grey;
  z-index: 999;
  padding: 10px;
  overflow-y: scroll;
  height: 100%;
}
.filter-area {
  display: block;
}
.filter-btn {
  display: none;
}
.page-btn {
  font-size: 11px;
}
@media (max-width: 1279px) {
  .pro-card {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (max-width: 1200px) {
  .filter-area {
    display: none;
  }
  .filter-btn {
    display: block;
  }
}
@media (min-width: 992px) {
  .modal-dialog {
    width: 95%;
  }
}
@media (max-width: 767px) {
  .pro-card {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .modal-dialog {
    width: 95%;
  }
}

.min {
  width: 47%;
  float: left;
}

.max {
  width: 47%;
  float: right;
}
.track-container {
  width: 100%;
  position: relative;
  cursor: pointer;
  height: 0.5rem;
}

.track,
.track-highlight {
  display: block;
  position: absolute;
  width: 100%;
  height: 0.5rem;
}

.track {
  background-color: #cccfd4;
}

.track-highlight {
  background-color: #293a99b3;
  z-index: 2;
}

.track-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  display: block;
  position: absolute;
  z-index: 2;
  width: 1.2rem;
  height: 1.2rem;
  top: calc(-50% - 0.15rem);
  margin-left: -0.7rem;
  border: none;
  background-color: #293a99;
  border-radius: 50%;
  -ms-touch-action: pan-x;
  touch-action: pan-x;
  transition: box-shadow 0.3s ease-out, background-color 0.3s ease,
    -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out,
    background-color 0.3s ease;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out,
    background-color 0.3s ease, -webkit-transform 0.3s ease-out;
}
.min-resp,
.max-resp {
  width: 113px;
}
</style>
