<template>
  <div id="modal-alert-area">
    <div
      class="modal fade"
      :class="modalAlert"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body px-4 pt-5 pb-3">
            <div class="d-flex">
              <div>
                <font-awesome
                  icon="check"
                  class="bg-success text-white p-1 rounded-circle me-1"
                />
              </div>
              <div>
                <p>
                  You have added
                  <span class="text-danger">{{ alertMsg }}</span> to your
                  shopping cart!
                </p>
              </div>
            </div>

            <div class="text-end">
              <button
                type="button"
                class="btn-danger btn col-1"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="modalClose()"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProductAddAlert",
  props: ["modalAlert", "alertMsg"],
  methods: {
    modalClose() {
      this.$emit("modalClose");
    },
  },
};
</script>
