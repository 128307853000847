<template>
  <div id="orderConfirm" class="col-md-12 px-3 mt-2 mb-5 mx-auto">
    <div class="row">
      <div class="col-md-12">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/" class="text-decoration-none"
                ><font-awesome icon="home"
              /></router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link
                to="/account"
                class="text-decoration-none text-capitalize"
                >Account</router-link
              >
            </li>
            <li class="breadcrumb-item">
              <a href="#" class="text-decoration-none text-capitalize"
                >My Orders</a
              >
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-md-12" v-if="orders.data">
        <table
          class="table table-hover table-border table-striped table-responsive"
        >
          <thead class="cbg-primary text-white">
            <th class="align-middle text-center">SL#</th>
            <th class="align-middle text-center">Order ID</th>
            <th class="align-middle text-center">Date</th>
            <th class="align-middle text-center">Status</th>
            <th class="align-middle text-center">Net Amount</th>
            <th class="align-middle text-center">Actions</th>
          </thead>
          <tbody>
            <tr v-for="(order, i) in orders.data" :key="order.order_id">
              <td>{{ ++i }}</td>
              <td>#{{ order.order_id }}</td>
              <td>{{ dateTime(order.created_at) }}</td>
              <td>
                <span
                  class="rounded p-1 text-white text-capitalize"
                  :class="
                    order.status == 'Cancel'
                      ? 'bg-danger'
                      : 'Delivered' == order.status
                      ? 'bg-success'
                      : 'bg-primary'
                  "
                  >{{ order.status }}</span
                >
              </td>
              <td>{{ order.net_amount.toLocaleString() }} ৳</td>
              <td>
                <router-link
                  :to="'/order-details/' + order.order_id"
                  target="_blank"
                >
                  <font-awesome icon="eye" class="btn cbtn-primary" />
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-12" v-else>
        <h4 class="text-center py-5">Order Empty!!!</h4>
      </div>
      <div class="col-md-12 bg-white rounded p-2 shadow" v-if="orders.total">
        <div v-if="orders.last_page > 1" class="d-inline-block">
          <div class="d-inline-block" v-if="orders.current_page == 1">
            <button class="btn btn-sm btn-success me-2 mt-1 page-btn">
              PREV
            </button>
          </div>
          <div class="d-inline-block" v-else>
            <button
              class="btn btn-sm btn-success me-2 mt-1 page-btn"
              @click="paginationPro(orders.current_page - 1)"
            >
              PREV
            </button>
          </div>
          <div
            class="d-inline-block"
            v-for="page in orders.last_page"
            :key="page"
          >
            <button
              v-if="
                page > orders.current_page - 4 && page < orders.current_page + 4
              "
              class="btn btn-sm link-btn me-2 mt-1"
              :class="page == orders.current_page ? 'link-active' : ''"
              @click="paginationPro(page)"
            >
              {{ page }}
            </button>
          </div>
          <div
            class="d-inline-block"
            v-if="orders.current_page == orders.last_page"
          >
            <button class="btn btn-sm btn-success mt-1 page-btn">NEXT</button>
          </div>
          <div class="d-inline-block" v-else>
            <button
              class="btn btn-sm btn-success mt-1 page-btn"
              @click="paginationPro(orders.current_page + 1)"
            >
              NEXT
            </button>
          </div>
        </div>
        <div class="page-number d-inline-block float-end">
          <p class="mt-1 mb-0">
            <small class="fw-bold page-btn"
              >Showing {{ orders.from }} to {{ orders.to }} of
              {{ orders.total }} ({{ orders.last_page }}
              {{ orders.last_page > 1 ? "Pages" : "Page" }})</small
            >
          </p>
        </div>
      </div>
    </div>

    <!-- loading view -->
    <LoadingView v-if="showLoading"/>

  </div>
</template>
<script>
import moment from "moment";
import LoadingView from '@/components/Layouts/LoadingView.vue';

export default {
  name: "OrderView",
  props: ["userInfo"],
  components: {
    LoadingView
  },
  data() {
    return {
      showLoading: true,
      orders: [],
      formData: {
        limit: 10,
        order: "asc",
        date: "",
        page: 1,
        customerId: this.userInfo.user.customer_id,
      },
    };
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    getOrders() {
      // on page loading
      this.showLoading = true;

      this.$store
        .dispatch("AllOrders", this.formData)
        .then((response) => {
          this.orders = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          // off page loading
          this.showLoading = false;
        });
    },
    dateTime(value) {
      return moment(value).format("DD MMM, YYYY hh:mma");
    },
    paginationPro(page) {
      this.formData.page = page;
      this.$store
        .dispatch("AllOrders", this.formData)
        .then((response) => {
          this.orders = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.orders = [];
        });
    },
  },
  watch: {},
};
</script>
<style scoped>
.cbg-primary,
.cbtn-primary {
  background: #293a99;
  color: white;
}
.link-btn {
  background: #1934cfad;
  color: white;
}

.link-active {
  background: #00008bc7;
  box-shadow: 0px 0px 4px 1px #1934cf;
}
.btn-primary:hover {
  box-shadow: 0px 3px 3px 1px darkblue;
  color: #fffcfccc;
}
.btn-secondary:hover {
  box-shadow: 0px 3px 3px 1px #648fb9;
  color: #fffcfccc;
}
</style>