import { createStore } from 'vuex';
import axios from "axios";
axios.defaults.baseURL = 'https://sopmaxadmin.compilebd.com/api/v1/';
var cartProduct = [];
var compareProduct = [];
var pcBuilderData = {
  totalAmount: 0,
};
var cartTotals = {
  subTotal: 0,
  total: 0,
  promoCode: 0,
  discountAmount: 0,
};
var userInfo = '';
if (localStorage.getItem("productCarts") !== null) {
  cartProduct = JSON.parse(localStorage.getItem('productCarts'));
  cartTotals = JSON.parse(localStorage.getItem('cartTotals'));
}
if (localStorage.getItem("compareProduct") !== null) {
  compareProduct = JSON.parse(localStorage.getItem('compareProduct'));
}
if (localStorage.getItem("userInfo") !== null) {
  userInfo = JSON.parse(localStorage.getItem('userInfo'));
}
if (localStorage.getItem("pcBuilderComponentCart") !== null) {
  pcBuilderData = JSON.parse(localStorage.getItem('pcBuilderComponentCart'));
}
export default createStore({

  // state
  state: {
    imgBaseUrl: 'http://ssopmaxadmin.compilebd.com/',
    product_categories: [],
    brands: [],
    sliders: [],
    fetaureProducts: [],
    productDetail: [],
    colorSizes: [],
    carts: cartProduct,
    cartTotal: cartTotals,
    products: [],
    typeFilters: '',
    filterProducts: '',
    categoryFilters: '',
    categoryProducts: '',
    subCategoryFilters: '',
    subCategoryProducts: '',
    compareProducts: compareProduct,
    userInfo: userInfo,
    districts: [],
    shippingArea: [],
    pcBuilderComponentCart: pcBuilderData,
    pcBuilderComponents: {},
    siteSetting: '',
  },

  // getters
  getters: {
    imgBaseUrl: state => {
      return state.imgBaseUrl;
    },

    product_categories: state => {
      return state.product_categories;
    },

    brands: state => {
      return state.brands;
    },

    sliders: state => {
      return state.sliders;
    },

    fetaureProducts: state => {
      return state.fetaureProducts;
    },

    productDetail: state => {
      return state.productDetail;
    },

    productCart: state => {
      return state.carts;
    },
    cartTotal: state => {
      return state.cartTotal;
    },
    products: state => {
      return state.products;
    },
    typeFilter: state => {
      return state.typeFilters;
    },
    filterProducts: state => {
      return state.filterProducts;
    },
    categoryFilter: state => {
      return state.categoryFilters;
    },
    categoryProducts: state => {
      return state.categoryProducts;
    },
    subCategoryFilter: state => {
      return state.subCategoryFilters;
    },
    subCategoryProducts: state => {
      return state.subCategoryProducts;
    },
    compareProducts: state => {
      return state.compareProducts;
    },
    userInfo: state => {
      return state.userInfo;
    },
    districts: state => {
      return state.districts;
    },
    shippingArea: state => {
      return state.shippingArea;
    },
    pcBuilderComponents: state => {
      return state.pcBuilderComponents;
    },
    pcBuilderComponentCart: state => {
      return state.pcBuilderComponentCart;
    },
    
    // site setting 
    siteSetting: state => {
      return state.siteSetting;
    },

  },

  // mutations
  mutations: {
    productCategorie(state, payload) {
      return state.product_categories = payload;
    },

    brand(state, payload) {
      return state.brands = payload;
    },

    slider(state, payload) {
      return state.sliders = payload;
    },

    fetaureProducts(state, payload) {
      return state.fetaureProducts = payload;
    },

    productDetail(state, payload) {
      return state.productDetail = payload;
    },

    colorSize(state, payload) {
      return state.colorSizes = payload;
    },

    productCart(state, payload) {
      state.carts.push({
        cartId: payload.cartId,
        product_id: payload.product.product_id,
        qty: payload.qty,
        variation_id: payload.variation_id,
        variation_name: payload.variation_name,
        product: payload.product,
      });

      var total = {
        subTotal: 0,
        total: 0,
        promoCode: 0,
        discountAmount: 0,
      };
      state.carts.forEach(item => {
        total.subTotal += item.qty * item.product.sale_price;
      });
      state.cartTotal = total;
      localStorage.setItem('productCarts', JSON.stringify(state.carts));
      localStorage.setItem('cartTotals', JSON.stringify(state.cartTotal));
    },

    cartQtyIncrement(state, payload) {
      payload.qty++;
      var total = {
        subTotal: 0,
        total: 0,
        promoCode: 0,
        discountAmount: 0,
      };
      state.carts.forEach(item => {
        total.subTotal += item.qty * item.product.sale_price;
      });
      state.cartTotal = total;
      localStorage.setItem('productCarts', JSON.stringify(state.carts));
      localStorage.setItem('cartTotals', JSON.stringify(state.cartTotal));
    },

    productCartRemove(state, payload) {
      state.carts = state.carts.filter(item => {
        return item.cartId !== payload;
      });
      var total = {
        subTotal: 0,
        total: 0,
        promoCode: 0,
        discountAmount: 0,
      };
      state.carts.forEach(item => {
        total.subTotal += item.qty * item.product.sale_price;
      });
      state.cartTotal = total;
      localStorage.setItem('productCarts', JSON.stringify(state.carts));
      localStorage.setItem('cartTotals', JSON.stringify(state.cartTotal));
    },
    clearCart(state) {
      state.carts = []
      state.cartTotal = 0;
      localStorage.setItem('productCarts', JSON.stringify(state.carts));
      localStorage.setItem('cartTotals', JSON.stringify(state.cartTotal));
    },
    products(state, payload) {
      state.products = payload;
    },
    typeFilter(state, payload) {
      state.typeFilters = payload;
    },
    filterProducts(state, payload) {
      state.filterProducts = payload;
    },
    categoryFilter(state, payload) {
      state.categoryFilters = payload;
    },
    categoryProducts(state, payload) {
      state.categoryProducts = payload;
    },
    subCategoryFilter(state, payload) {
      state.subCategoryFilters = payload;
    },
    subCategoryProducts(state, payload) {
      state.subCategoryProducts = payload;
    },
    compareProduct(state, payload) {
      const compareItem = state.compareProducts.find(item => item.product_id === payload.product_id);
      if (!compareItem) {
        if (state.compareProducts.length !== 3) {
          state.compareProducts.push({
            product_id: payload.product_id,
            product: payload,
          });
          localStorage.setItem('compareProduct', JSON.stringify(state.compareProducts));
        } else {
          state.compareProducts = state.compareProducts.filter(item => {
            return item.product_id !== state.compareProducts[0].product_id;
          });
          state.compareProducts.push({
            product_id: payload.product_id,
            product: payload,
          });
          localStorage.setItem('compareProduct', JSON.stringify(state.compareProducts));
          // console.log(state.compareProducts)
        }
      }
    },
    productCompareRemove(state, payload) {
      state.compareProducts = state.compareProducts.filter(item => {
        return item.product_id !== payload;
      });
      localStorage.setItem('compareProduct', JSON.stringify(state.compareProducts));
    },
    clearCompare(state) {
      state.compareProducts = []
      localStorage.setItem('compareProduct', JSON.stringify(state.compareProducts));
    },
    logIn(state, payload) {
      state.userInfo = payload.data;
      localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
    },
    profileUpdate(state, payload) {
      state.userInfo.user = payload;
      localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
    },
    logout(state) {
      state.userInfo = '';
      localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
    },
    districts(state, payload) {
      state.districts = payload;
    },
    shippingArea(state, payload) {
      state.shippingArea = payload;
    },
    pcBuilderComponents(state, payload) {
      state.pcBuilderComponents = payload;
    },
    
    // pc builder cart data store to session
    pcBuilderCart(state, payload) {
      let name = 'componentId' + payload.componentId;
      // let component = [];
      if (payload.maxQty > 1) {
        if (state.pcBuilderComponentCart[name]) {
          if (payload.id) {
            let oldMultipleProduct = state.pcBuilderComponentCart[name].products.find(item => item.id == payload.id);
            let newAmount = (state.pcBuilderComponentCart.totalAmount - oldMultipleProduct.product.sale_price);
            oldMultipleProduct.product = payload.product;
            state.pcBuilderComponentCart.totalAmount = (newAmount + payload.product.sale_price);
          } else {
            // console.log(state.pcBuilderComponentCart[name].products)
            state.pcBuilderComponentCart[name].products.push({
              id: Math.round(Math.random() * 100 + Math.random() * 100),
              qty: 1,
              product: payload.product,
            });
            state.pcBuilderComponentCart.totalAmount += payload.product.sale_price;
          }
        } else {
          let multiProduct = [];
          multiProduct.push({
            id: Math.round(Math.random() * 100 + Math.random() * 100),
            qty: 1,
            product: payload.product,
          });

          // component.push({
          //   componentId: payload.componentId,
          //   multiple: true,
          //   products: multiProduct,
          // });
          state.pcBuilderComponentCart[name] = {
            componentId: payload.componentId,
            multiple: true,
            products: multiProduct,
          };
          state.pcBuilderComponentCart.totalAmount += payload.product.sale_price;
        }
      } else {
        // if exists old component
        if (state.pcBuilderComponentCart[name]) {
          let newAmount = (state.pcBuilderComponentCart.totalAmount - state.pcBuilderComponentCart[name].products[0].product.sale_price);
          // component.push({
          //   componentId: payload.componentId,
          //   multiple: false,
          //   qty: payload.qty,
          //   products: [{product: payload.product}],
          // });            
          state.pcBuilderComponentCart[name] = {
            componentId: payload.componentId,
            multiple: false,
            qty: 1,
            products: [{ product: payload.product }],
          };
          state.pcBuilderComponentCart.totalAmount = newAmount + payload.product.sale_price;
        } else {
          // component.push({
          //   componentId: payload.componentId,
          //   multiple: false,
          //   qty: payload.qty,
          //   products: [{product: payload.product}],
          // });
          state.pcBuilderComponentCart[name] = {
            componentId: payload.componentId,
            multiple: false,
            qty: 1,
            products: [{ product: payload.product }],
          };
          state.pcBuilderComponentCart.totalAmount += payload.product.sale_price;
        }
      }
      localStorage.setItem('pcBuilderComponentCart', JSON.stringify(state.pcBuilderComponentCart));
    },

    // remove product from pc buiilder component cart
    removePcBuilderComponent(state, payload) {
      let name = "componentId" + payload.componentId;
      let amount = 0;
      if (state.pcBuilderComponentCart[name] && payload.index) {
        state.pcBuilderComponentCart[name].products = state.pcBuilderComponentCart[name].products.filter(item => {
          if (item.id == payload.index) {
            amount = item.product.sale_price;
          }
          return item.id != payload.index;
        });
        if (state.pcBuilderComponentCart[name].products.length == 0) {
          delete state.pcBuilderComponentCart[name];
        }
      } else if (state.pcBuilderComponentCart[name]) {
        amount = state.pcBuilderComponentCart[name].products[0].product.sale_price;
        delete state.pcBuilderComponentCart[name];
      }
      state.pcBuilderComponentCart.totalAmount = (state.pcBuilderComponentCart.totalAmount - amount);
      localStorage.setItem('pcBuilderComponentCart', JSON.stringify(state.pcBuilderComponentCart));
    },

    // budget pc components add to pc builder component cart
    componentAddToPcBuilder(state, payload) {
      state.pcBuilderComponentCart = {
        totalAmount: 0,
      };
      payload.forEach(product => {
        let componentID = 'componentId' + product.category_id;
        // let component = [];
        if (product.max_qty > 1) {
          if (state.pcBuilderComponentCart[componentID]) {
            state.pcBuilderComponentCart[componentID].products.push({
              id: Math.round(Math.random() * 100 + Math.random() * 100),
              qty: 1,
              product: product,
            });
            state.pcBuilderComponentCart.totalAmount += product.sale_price;

          } else {
            let multiProduct = [];
            multiProduct.push({
              id: Math.round(Math.random() * 100 + Math.random() * 100),
              qty: 1,
              product: product,
            });

            state.pcBuilderComponentCart[componentID] = {
              componentId: componentID,
              multiple: true,
              products: multiProduct,
            };
            state.pcBuilderComponentCart.totalAmount += product.sale_price;
          }
        } else {
          state.pcBuilderComponentCart[componentID] = {
            componentId: componentID,
            multiple: false,
            qty: 1,
            products: [{ product: product }],
          };
          state.pcBuilderComponentCart.totalAmount += product.sale_price;
        }
      })
      localStorage.setItem('pcBuilderComponentCart', JSON.stringify(state.pcBuilderComponentCart));
      return true;
    },

    // site setting
    siteSetting(state, payload) {
      state.siteSetting = payload;
    },


  },

  // action
  actions: {
    productCategory(context) {
      return new Promise((resolve, reject) => {
        axios.get('category')
          .then((response) => {
            context.commit('productCategorie', response.data);
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    allBrand(context) {
      return new Promise((resolve, reject) => {
        axios.get('brands')
          .then((response) => {
            context.commit('brand', response.data);
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    offerSlider(context) {
      return new Promise((resolve, reject) => {
        axios.get('sliders')
          .then((response) => {
            context.commit('slider', response.data);
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },


    featureProducts(context) {
      return new Promise((resolve, reject) => {
        axios.get('feature-products')
          .then((response) => {
            context.commit('fetaureProducts', response.data);
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    productDetailf(context, productSlug) {
      return new Promise((resolve, reject) => {
        axios.get('product-details/' + productSlug)
          .then((response) => {
            context.commit('productDetail', response.data);
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },


    // add to cart function
    addToCart(context, data) {
      const cartItem = context.state.carts.find(item => item.cartId === data.cartId);
      if (!cartItem) {
        context.commit('productCart', data);
      } else {
        context.commit('cartQtyIncrement', cartItem);
      }
    },
    removeProduct(context, cartId) {
      context.commit('productCartRemove', cartId);
    },
    clearCart(context) {
      context.commit('clearCart');
    },

    // category product
    categoryProducts(context, data) {
      let url = "?limit=" + data.limit;
      // status = 1,2 & brands=1, 2, 3 & attributes=1, 2, 3, 4, 6, 7 & price=0, 555555 & order=asc & limit=2 & page=2;
      if (data.stock_status.length) {
        url += "&status=" + data.stock_status.toString();
      }
      if (data.brand_id.length) {
        url += "&brands=" + data.brand_id.toString();
      }
      if (data.variation_id.length) {
        url += "&variations=" + data.variation_id.toString();
      }
      if (data.price.length) {
        url += "&price=" + data.price.toString();
      }
      if (data.order.length) {
        url += "&order=" + data.order;
      }
      return new Promise((resolve, reject) => {
        axios.get('category-products/' + data.slug + url + "&page=" + data.page)
          .then((response) => {
            context.commit('categoryProducts', response.data);
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // category product brand and atribute data fetch
    categoryProductAttributeBrand(context, slug) {
      return new Promise((resolve, reject) => {
        axios.get('category-brand-attributes/' + slug)
          .then((response) => {
            // context.commit('categoryProducts', response.data);
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    addtoCompare(context, product) {
      context.commit('compareProduct', product);
    },
    removeCompareProduct(context, product_id) {
      context.commit('productCompareRemove', product_id);
    },
    clearCompare(context) {
      context.commit('clearCompare');
    },
    UserLogin(context, formData) {
      return new Promise((resolve, reject) => {
        axios.post('auth/login', formData)
          .then((response) => {
            context.commit('logIn', response.data);
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    userLogout(context, formData) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.userInfo.auth_token;
        axios.post('auth/logout', formData.auth_token)
          .then((response) => {
            context.commit('logout');
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    UserProfile(context) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.userInfo.auth_token;
        axios.get('user-profile')
          .then((response) => {
            // context.commit('logout');
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getDistricts(context) {
      return new Promise((resolve, reject) => {
        axios.get('district')
          .then((response) => {
            context.commit('districts', response.data);
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    
    // get shipping area function
    getShippingArea(context) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.userInfo.auth_token;
        axios.get('shipping-area')
          .then((response) => {
            context.commit('shippingArea', response.data);
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    Userregister(context, formData) {
      return new Promise((resolve, reject) => {
        axios.post('auth/register', formData)
          .then((response) => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    profileUpdate(context, formData) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.userInfo.auth_token;
        axios.put('auth/profile/update', formData)
          .then((response) => {
            context.commit('profileUpdate', response.data.data);
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    passwordUpdate(context, formData) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.userInfo.auth_token;
        axios.put('auth/password-update', formData)
          .then((response) => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getQuestinos(context, formData) {
      return new Promise((resolve, reject) => {
        axios.get('questions/' + formData.productId + '?limit=' + formData.limit + '&order=' + formData.order + '&page=' + formData.page)
          .then((response) => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getReviews(context, formData) {
      return new Promise((resolve, reject) => {
        axios.get('reviews/' + formData.productId + '?limit=' + formData.limit + '&order=' + formData.order + '&page=' + formData.page)
          .then((response) => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    reviewStore(context, formData) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.userInfo.auth_token;
        axios.post('review/store', formData)
          .then((response) => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    questionStore(context, formData) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.userInfo.auth_token;
        axios.post('question/store', formData)
          .then((response) => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    districts(context) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.userInfo.auth_token;
        axios.get('districts')
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    promoCode(context, code) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.userInfo.auth_token;
        axios.get('promo-code/' + code)
          .then(response => {
            resolve(response);
          }).catch(error => {
            reject(error);
          })
      });
    },
    OrderConfirm(context, formData) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.userInfo.auth_token;
        axios.post('order/confirm', formData)
          .then(response => {
            resolve(response);
          }).catch(error => {
            reject(error);
          })
      });
    },
    AllOrders(context, data) {
      let url = "?limit=" + data.limit;
      // status = 1,2 & brands=1, 2, 3 & attributes=1, 2, 3, 4, 6, 7 & price=0, 555555 & order=asc & limit=2 & page=2;
      if (data.date.length) {
        url += "&status=" + data.date;
      }
      if (data.order.length) {
        url += "&order=" + data.order;
      }

      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.userInfo.auth_token;
        axios.get('all-orders' + url + "&page=" + data.page)
          .then(response => {
            resolve(response);
          }).catch(error => {
            reject(error);
          })
      });
    },

    // get offer
    GetOffer() {
      return new Promise((resolve, reject) => {
        axios.get('all-offers').then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },

    // offer info
    GetOfferInfo(context, data) {
      return new Promise((resolve, reject) => {
        axios.get('offer-info/' + data).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      });
    },

    // offer product 
    offerProducts(context, data) {
      return new Promise((resolve, reject) => {
        axios.get('offer-products/' + data.slug + '?limit=' + data.limit + '&order=' + data.order + '&page=' + data.page)
          .then((response) => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // order details
    OrderDetails(context, order_id) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.userInfo.auth_token;
        axios.get('order-details/' + order_id).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    },

    // brand product 
    brandProducts(context, data) {
      return new Promise((resolve, reject) => {
        let url = "?limit=" + data.limit;
        // status = 1,2 & brands=1, 2, 3 & attributes=1, 2, 3, 4, 6, 7 & price=0, 555555 & order=asc & limit=2 & page=2;
        if (data.stock_status.length) {
          url += "&status=" + data.stock_status.toString();
        }
        if (data.variation_id.length) {
          url += "&variations=" + data.variation_id.toString();
        }
        if (data.price.length) {
          url += "&price=" + data.price.toString();
        }
        if (data.order.length) {
          url += "&order=" + data.order;
        }
        axios.get('brand-products/' + data.slug + url + '&page=' + data.page)
          .then((response) => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    SearchProduct(context, data) {
      return new Promise((resolve, reject) => {
        let url = "?limit=" + data.limit;
        // status = 1,2 & brands=1, 2, 3 & attributes=1, 2, 3, 4, 6, 7 & price=0, 555555 & order=asc & limit=2 & page=2;
        if (data.order.length) {
          url += "&order=" + data.order;
        }
        axios.get('product-search/' + data.searchText + url + '&page=' + data.page).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      })
    },

    // pc builder component data fetch function
    getPcBuilderComponents(context) {
      return new Promise((resolve, reject) => {
        axios.get('pc-builder/components').then(response => {
          context.commit('pcBuilderComponents', response.data.data);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },

    // pc builder components product add to pc builder session cart
    addToPcBuilder(context, data) {
      context.commit('pcBuilderCart', data);
    },

    // remove pc builder components product
    removePcBuilderComponent(context, data) {
      context.commit('removePcBuilderComponent', data);

    },

    // clearc pc buildr session cart
    clearPcBuilderCart(context) {
      context.commit('clearCart');
    },

    // budget pc products 
    getBudgetPcProducts(context, data) {
      let url = "?product_type=Budget PC&limit=" + data.limit;
      // status = 1,2 & brands=1, 2, 3 & attributes=1, 2, 3, 4, 6, 7 & price=0, 555555 & order=asc & limit=2 & page=2;
      if (data.variation_id.length) {
        url += "&variations=" + data.variation_id.toString();
      }
      if (data.price.length) {
        url += "&price=" + data.price.toString();
      }
      if (data.order.length) {
        url += "&order=" + data.order;
      }
      return new Promise((resolve, reject) => {
        axios.get('budget-pc' + url + "&page=" + data.page)
          .then((response) => {
            // context.commit('budgetPcProducts', response.data);
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // budget pc components add to pc builder cart
    componentAddToPcBuilder(context, components) {
      return new Promise((resolve) => {
        // context.commit('componentAddToPcBuilder', components);
        resolve(context.commit('componentAddToPcBuilder', components));
      });
    },

    // pc builder data save function
    savePcBuilder(context, formData) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.userInfo.auth_token;
        axios.post('pc-builder/store', formData)
          .then((response) => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          });
      });
    },

    // saved pc data fetch function
    savedPC(context, userId) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.userInfo.auth_token;
        axios.get('pc-builder/user-report/' + userId)
          .then((response) => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          });
      });
    },


    // get setting data
    getSettingData(context){
      return new Promise((resolve, reject) => {
        axios.get('site-setting')
          .then((response) => {
            context.commit('siteSetting',response.data.data);
            resolve(response.data)
            // console.log(response);
          })
          .catch(error => {
            reject(error)
          });
      });
    }

  },
  modules: {
  }
})
