<template>
    <div class="product-area col-md-12 px-3 mt-2 mb-5">
        <div class="row">
            <div class="col-md-12">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                        <router-link to="/" class="text-decoration-none"
                            ><font-awesome icon="home"
                        /></router-link>
                        </li>
                        <li class="breadcrumb-item">
                        <a href="#" class="text-decoration-none text-capitalize">Terms and Conditions</a>
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
        <div class="row bg-white sort-area rounded mx-1 py-2 mb-3">
            <div class="col-md-12 p-4">
                <div v-html="termsConditions"></div>
            </div>
        </div>
    </div>
</template>
<script>

export default{
    name: 'TermsConditions',
    data(){
        return{
            termsConditions: '',
        }
    },

    methods: {
        // setting data fetch function
        getSettingData(){
            let setting = this.$store.getters.siteSetting;
            if(setting){
                this.termsConditions = setting.termsConditions;
            }else{
                console.log('server');
                this.$store.dispatch("getSettingData").then((response) => {
                    this.termsConditions = response.data.termsConditions;
                    // console.log(response);
                })
                .catch((error) => {
                    console.log(error);
                });
            }
        }
    },

      // 
    mounted() {
        // call getSettingData for get site setting data
        this.getSettingData();
    },
    
}
</script>